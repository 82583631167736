import React from 'react';
import { IonFab, IonFabButton, IonIcon, useIonAlert, useIonToast } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

export interface EnterBagCodeFabProps {
  submitHandler: (bagCode: string) => void;
}

const EnterBagCodeFab = ({ submitHandler }: EnterBagCodeFabProps) => {
  const [presentAlert] = useIonAlert();
  const { t } = useTranslation();

  const openEnterCodeAlert = () => {
    presentAlert({
      header: t('Enter bag ID'),
      backdropDismiss: false,
      keyboardClose: false,
      inputs: [
        {
          type: 'number',
          name: 'code',
          placeholder: t('Enter bag ID'),
        },
      ],
      buttons: [
        {
          text: t('Cancel'),
          role: 'cancel',
        },
        {
          text: t('Ok'),
          handler: (input) => submitHandler(input.code),
        },
      ],
    });
  };

  return (
    <IonFab onClick={openEnterCodeAlert} vertical="bottom" horizontal="end" slot="fixed">
      <IonFabButton>
        <IonIcon icon={addOutline} />
      </IonFabButton>
    </IonFab>
  );
};

export { EnterBagCodeFab };
