import React, { useEffect, useState } from 'react';
import { IonLoading, IonRouterOutlet } from '@ionic/react';
import { Route, Redirect } from 'react-router-dom';
import { Network } from '@capacitor/network';
import { Storage } from '@capacitor/storage';

import { Home } from '../pages/home/Home';
import { useAuth } from '../contexts/auth-context';
import { NoConnection } from '../pages/no-connection/NoConnection';
import { BagDetail } from '../pages/bagDetail/BagDetail';
import { OrderDetail } from '../pages/orderDetail/OrderDetail';
import { useTranslation } from 'react-i18next';
import { SigninPage } from '../pages/signin/Signin';
import { Bags } from '../pages/bags/Bags';
import { DeliveryHistoryBags } from '../pages/deliveryHistoryBags/DeliveryHistoryBags';
import { DeliveryHistoryBagDetail } from '../pages/deliveryHistoryBagDetail/DeliveryHistoryBagDetail';
import { Schedule } from '../pages/schedule/Schedule';

export interface AppPageLink {
  id: number;
  url: string;
  title: string;
}

export interface AppPage extends AppPageLink {
  icon: string;
  iconStyle: string;
  description: string;
  cssClasses: string;
}

export interface AppRoute {
  title: string;
  url: string;
  page: React.FC;
}

const routes: AppRoute[] = [
  {
    title: 'Home',
    url: '/home',
    page: Home,
  },
  {
    title: 'Bags',
    url: '/bags',
    page: Bags,
  },
  {
    title: 'Bag detail',
    url: '/bags/bag/:id(\\d+)',
    page: BagDetail,
  },
  {
    title: 'Order detail',
    url: '/order/:id(\\d+)',
    page: OrderDetail,
  },
  {
    title: 'Deliveries history',
    url: '/history',
    page: DeliveryHistoryBags,
  },
  {
    title: 'Completed bag',
    url: '/history/bag/:id(\\d+)',
    page: DeliveryHistoryBagDetail,
  },
  {
    title: 'Schedule',
    url: '/schedule/',
    page: Schedule,
  },
];

const Router: React.FC = () => {
  const authContext = useAuth();
  const [internetConnectionStatus, setInternetConnectionStatus] = useState<boolean>(true);
  const [toggleSkip, setToggleSkip] = useState<boolean>(false);
  // const { isAlways } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const checkName = async () => {
      const { value } = await Storage.get({ key: 'LocationSkip' });
      if (value !== null) setToggleSkip(!!JSON.parse(value));
    };
    checkName();
  }, []);

  useEffect(() => {
    Storage.set({
      key: 'LocationSkip',
      value: JSON.stringify(toggleSkip),
    });
  }, [toggleSkip]);

  useEffect(() => {
    Network.addListener('networkStatusChange', (status: any) => {
      setInternetConnectionStatus(status.connected);
    });

    return () => {
      Network.removeAllListeners();
    };
  }, []);

  if (!internetConnectionStatus) {
    return <NoConnection internetConnectionStatus />;
  }

  // const isAndroidPlatform = isPlatform('android') && !isPlatform('mobileweb');
  // if (isAndroidPlatform && !isAlways) {
  //   return <SigninGeolocation />;
  // }

  // const isIOSPlatform = isPlatform('ios') && !isPlatform('mobileweb');
  // if (isIOSPlatform && !toggleSkip && !isAlways) {
  //   return <SigninGeolocation setToggleSkip={setToggleSkip} />;
  // }

  if (authContext.isFetchingFromStorage) {
    return <IonLoading isOpen={true} message={t('Loading...')} />;
  }

  if (!authContext.user) {
    return <SigninPage />;
  }

  return (
    <IonRouterOutlet id="main">
      {routes.map((route) => (
        <Route key={route.url} exact path={route.url} component={route.page} />
      ))}
      <Route path="/" exact>
        <Redirect to="/bags" />
      </Route>
    </IonRouterOutlet>
  );
};

export { Router };
