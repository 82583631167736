import React from 'react';
import personImage from '../../../assets/images/person.png';

export interface IImageProps {
  url: string;
  className?: string;
}

// const API_URL = process.env.REACT_APP_API_URLL;

const Image = ({ url, className }: IImageProps) => {
  return <img className={className} src={url} alt="" />;
};

Image.defaultProps = {
  url: personImage,
  className: '',
};

export { Image };
