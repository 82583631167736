import React, { useCallback, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

interface ActionType {
  type: 'SUCCESS' | 'DANGER' | 'REGULAR' | 'DISMISS' | 'WARNING';
  message: string;
}

interface ToastType {
  message: string;
  color: 'success' | 'danger' | 'medium' | 'warning';
}

const initialToastState: ToastType = { message: '', color: 'medium' };

const toastReducer = (state: ToastType, action: ActionType): ToastType => {
  switch (action.type) {
    case 'SUCCESS':
      return { message: action.message, color: 'success' };
    case 'DANGER':
      return { message: action.message, color: 'danger' };
    case 'WARNING':
      return { message: action.message, color: 'warning' };
    case 'REGULAR':
      return { message: action.message, color: 'medium' };
    case 'DISMISS':
      return initialToastState;
    default:
      return state;
  }
};

const ToastContext = React.createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dispatch: (action: ActionType) => {},
  message: '',
  color: '',
});

const ToastProvider = (props: any) => {
  const { t } = useTranslation();
  const [{ color, message }, dispatch] = useReducer(toastReducer, initialToastState);

  const tDispatch = useCallback(
    ({ message: mes, type }: ActionType) => {
      const tMessage = t(mes);
      dispatch({ type, message: tMessage });
    },
    [t]
  );

  const value = useMemo(() => ({ dispatch: tDispatch, color, message }), [tDispatch, color, message]);

  return <ToastContext.Provider value={value} {...props} />;
};

const useToast = () => React.useContext(ToastContext);

export { ToastProvider, useToast };
