import React, { useEffect, useState } from 'react';
import { IonAlert, IonContent, IonItem, IonList, IonMenu, IonMenuToggle, IonText } from '@ionic/react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './Menu.module.scss';
import { useAuth } from '../../contexts/auth-context';
import { AppPageLink } from '../../routes/Router';
import { Image } from '../UI/Image/Image';
import { useDriverStats } from '../../contexts/statistics-context';

const appPageLinks: AppPageLink[] = [
  {
    id: 2,
    title: 'Bags',
    url: '/bags',
  },
  {
    id: 3,
    title: 'Deliveries history',
    url: '/history',
  },
  {
    id: 4,
    title: 'Schedule',
    url: '/schedule',
  },
];

const dummyStatsData = {
  today: {
    bagCount: 4,
    orderCount: 6,
    totalAmount: 2334,
    earned: 1213,
    rating: 4.6,
  },
  week: {
    bagCount: 10,
    orderCount: 10,
    totalAmount: 9334,
    earned: 1523,
    rating: 3.4,
  },
  month: {
    bagCount: 24,
    orderCount: 443,
    totalAmount: 23240,
    earned: 1223,
    rating: 4.2,
  },
};

const Menu = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [showLogoutAlert, setShowLogoutAlert] = useState(false);
  const authContext = useAuth();
  const [appVersion, setAppVersion] = useState('');

  const { statistics, statisticsLoading, error } = useDriverStats();

  useEffect(() => {
    if (process.env.REACT_APP_VERSION) {
      setAppVersion(process.env.REACT_APP_VERSION);
    }
  }, []);

  return (
    <>
      <IonAlert
        isOpen={showLogoutAlert}
        onDidDismiss={() => setShowLogoutAlert(false)}
        header={t('Logout')}
        message={t('Confirm logout')}
        buttons={[
          {
            text: t('Cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('Logout'),
            handler: () => {
              authContext.logout();
            },
          },
        ]}
      />
      <IonMenu hidden={!authContext.user} swipeGesture={!!authContext.user} contentId="main" type="overlay">
        <IonContent className={styles.purpleBackground}>
          <div className={styles.menuContainer}>
            <IonList className="ion-no-padding" lines="full">
              <IonMenuToggle autoHide={false}>
                <div className={styles.menuHeader}>
                  <Image className={styles.headerImg} />
                  <div className={styles.driverData}>
                    <div className={styles.username}>
                      {authContext.user?.firstName} {authContext.user?.secondName}
                    </div>
                    <div>{`${t('Earned per month')}: ${statistics?.month?.driverEarnings?.toLocaleString()} ₽`}</div>
                    {/*
                    backend is not ready yet
                    <div className={styles.rating}>
                      <div className={styles.star}>☆</div>
                      <div className={styles.rate}>{dummyStatsData.month.rating}</div>
                    </div>
                    */}
                  </div>
                </div>
                {appPageLinks.map(({ id, url, title }) => {
                  if (url.includes('http')) {
                    return (
                      <a style={{ textDecoration: 'none' }} key={id} href={url}>
                        <IonItem
                          detail={false}
                          className={`/${pathname.split('/')[1]}` === url ? styles.lastElement : styles.pageButton}
                        >
                          <IonText>{t(title)}</IonText>
                        </IonItem>
                      </a>
                    );
                  }
                  return (
                    <Link style={{ textDecoration: 'none' }} key={id} to={url}>
                      <IonItem
                        detail={false}
                        className={`/${pathname.split('/')[1]}` === url ? styles.lastElement : styles.pageButton}
                      >
                        <IonText>{t(title)}</IonText>
                      </IonItem>
                    </Link>
                  );
                })}
                <IonItem detail={false} className={styles.pageButton} onClick={() => setShowLogoutAlert(true)}>
                  {t('Logout')}
                </IonItem>
              </IonMenuToggle>
            </IonList>
            {appVersion && <p className={styles.appVersion}>{`v.${appVersion}`}</p>}
          </div>
        </IonContent>
      </IonMenu>
    </>
  );
};

export { Menu };
