import React from 'react';
import { IonButton, IonCol, IonRow } from '@ionic/react';
import classNames from 'classnames/bind';
import styles from '../OrderDetail.module.scss';
import { useTranslation } from 'react-i18next';
import { OrderDeliveryStatus } from '../../../generated/graphql';

interface IOrderDetailStatus {
  deliveryStatus: OrderDeliveryStatus;
  handleOrderCompletedButton: () => void;
  handleStartDeliveryButton: () => void;
}

const cx = classNames.bind(styles);

const OrderDetailStatus: React.FC<IOrderDetailStatus> = ({
  deliveryStatus,
  handleOrderCompletedButton,
  handleStartDeliveryButton,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {(deliveryStatus === OrderDeliveryStatus.A_2 || deliveryStatus === OrderDeliveryStatus.A_5) && (
        <IonRow className={cx('ion-no-padding', 'button__row')}>
          <IonCol>
            <IonButton
              className={cx('order__button')}
              disabled={deliveryStatus === OrderDeliveryStatus.A_5}
              onClick={handleOrderCompletedButton}
              color="success"
              expand="block"
            >
              {t('Complete delivery')}
            </IonButton>
          </IonCol>
        </IonRow>
      )}
      {deliveryStatus === OrderDeliveryStatus.A_1 && (
        <IonRow className={cx('ion-no-padding', 'button__row')}>
          <IonCol>
            <IonButton
              className={cx('order__button')}
              onClick={handleStartDeliveryButton}
              color="primary"
              expand="block"
            >
              {t('Start delivery')}
            </IonButton>
          </IonCol>
        </IonRow>
      )}
    </>
  );
};

export default OrderDetailStatus;
