import React from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react';
import { OrderDetailDescrLabel } from '../OrderDetailDescrLabel/OrderDetailDescrLabel';
import classNames from 'classnames/bind';
import styles from '../OrderDetail.module.scss';
import { useTranslation } from 'react-i18next';

interface IOrderDetailDescription {
  orderNumber: string;
  houseNumber: string;
  entrance: string;
  floor: string;
  apartmentNumber: string;
  city: string;
  street: string;
  deliveryInterval: string;
  deliveryDate: string;
  shippingMethod: string;
  paymentMethod: string;
  phone: string;
  fullName: string;
}

const cx = classNames.bind(styles);

const OrderDetailDescription: React.FC<IOrderDetailDescription> = ({
  orderNumber,
  houseNumber,
  entrance,
  floor,
  apartmentNumber,
  city,
  street,
  deliveryInterval,
  deliveryDate,
  shippingMethod,
  paymentMethod,
  phone,
  fullName,
}) => {
  const { t } = useTranslation();

  return (
    <IonCard className={cx('order-details')}>
      <IonCardHeader className={cx('order-details-header')} color="primary">
        <IonCardTitle className={cx('order-details-title')}>{`${t('Order Detail')} #${orderNumber}`}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className={cx('order-details-content', 'ion-padding ion-margin-top')}>
        <IonCardSubtitle className={cx('order-details-subtitle')}>{t('Shipping Address')}</IonCardSubtitle>
        <div className={cx('order-details-description')}>
          {!houseNumber ? (
            <OrderDetailDescrLabel title={`${t('Address')}: `} titleClassName={'ion-text-left'} descr={street} />
          ) : (
            <>
              <OrderDetailDescrLabel title={`${t('City')}: `} titleClassName={'ion-text-left'} descr={city} />
              <OrderDetailDescrLabel title={`${t('Street')}: `} titleClassName={'ion-text-left'} descr={street} />
              <OrderDetailDescrLabel
                title={`${t('House number')}: `}
                titleClassName={'ion-text-left'}
                descr={houseNumber}
              />
              {!!entrance && !!floor && !!apartmentNumber && (
                <>
                  <OrderDetailDescrLabel
                    title={`${t('Entrance')}: `}
                    titleClassName={'ion-text-left'}
                    descr={entrance}
                  />
                  <OrderDetailDescrLabel title={`${t('Floor')}: `} titleClassName={'ion-text-left'} descr={floor} />
                  <OrderDetailDescrLabel
                    title={`${t('Apartment number')}: `}
                    titleClassName={'ion-text-left'}
                    descr={apartmentNumber}
                  />
                </>
              )}
            </>
          )}
        </div>

        <IonCardSubtitle className={cx('order-details-subtitle')}>{t('Delivery date and interval')}</IonCardSubtitle>
        <div className={cx('order-details-description', 'detail__descr')}>
          <OrderDetailDescrLabel title={`${t('Time')}: `} titleClassName={'ion-text-left'} descr={deliveryInterval} />
        </div>
        <div className={cx('order-details-description')}>
          <OrderDetailDescrLabel title={`${t('Date')}: `} titleClassName={'ion-text-left'} descr={deliveryDate} />
        </div>

        <IonCardSubtitle className={cx('order-details-subtitle')}>{t('Shipping Method')}</IonCardSubtitle>
        <div className={cx('order-details-description')}>
          <OrderDetailDescrLabel title={shippingMethod} />
        </div>

        <IonCardSubtitle className={cx('order-details-subtitle')}>{t('Payment Method')}</IonCardSubtitle>
        <div className={cx('order-details-description')}>
          <OrderDetailDescrLabel title={paymentMethod} />
        </div>

        <IonCardSubtitle className={cx('order-details-subtitle')}>{t('Contacts')}</IonCardSubtitle>
        <div className={cx('order-details-description')}>
          <OrderDetailDescrLabel title={`${t('Full name')}: `} descr={fullName} />
          <a href={`tel:${phone}`} className={cx('detail__label')}>
            {phone}
          </a>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default OrderDetailDescription;
