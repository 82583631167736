import React, { SetStateAction, useEffect, useState } from 'react';
import { IonContent, IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react';
import { Title } from '../../components/title/Title';
import { BagDetailComponent } from '../../components/bagDetail/BagDetail';
import { OrderTypeEdge, useDriverHistoryOrdersLazyQuery } from '../../generated/graphql';
import { EmptyPageGrid } from '../../components/EmptyPageGrid/EmptyPageGrid';
import { cubeOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import styles from '../bagDetail/BagDetail.module.scss';
import { useLocation } from 'react-router';

interface IDeliveryHistoryBagDetail {}

interface ILocation {
  bagId: string;
  bagCode: string;
}

const DeliveryHistoryBagDetail = () => {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const location = useLocation<ILocation>();

  const [getOrders, { loading, error, data, refetch: refetchOrders }] = useDriverHistoryOrdersLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const [bagCode, setBagCode] = useState('');

  useEffect(() => {
    getOrders({ variables: { ordersBag: location.state.bagId } });
  }, []);

  useIonViewWillEnter(() => {
    setBagCode(location.state.bagCode);
    refetchOrders();
  }, [location]);

  return (
    <IonPage>
      {loading ? (
        <IonLoading isOpen message={t('Loading...')} />
      ) : (
        <>
          <Title
            translucent
            backButton
            title={`${t('Bag')} №${bagCode}`}
            menuButton
            refreshButton={{
              refreshButton: true,
              callback: () => {
                refetchOrders();
              },
            }}
          />
          <IonContent>
            <p className={cx('font--bold', 'ion-text-center')}>{t('Orders')}</p>
            {data?.orders?.edges?.length ? (
              <BagDetailComponent orders={data?.orders?.edges as Array<OrderTypeEdge>} />
            ) : (
              <EmptyPageGrid title={'Order list is empty'} icon={cubeOutline} />
            )}
          </IonContent>
        </>
      )}
    </IonPage>
  );
};

export { DeliveryHistoryBagDetail };
