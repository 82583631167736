import React from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames/bind';
import styles from '../../../pages/orderDetail/OrderDetail.module.scss';

interface IOrderDetailNote {
  note: string;
}

const OrderDetailNote: React.FC<IOrderDetailNote> = ({ note }) => {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  return (
    <IonCard>
      <IonCardHeader color="primary" className={cx('order-details-header')}>
        <IonCardTitle className={cx('order-details-title')}>{t('Order Notes')}</IonCardTitle>
      </IonCardHeader>

      <IonCardContent
        className={cx('ion-no-padding ion-margin-top', 'order-details-description', 'order-details-note')}
      >
        <p className={cx('order-details-description', 'order-note')}>{note}</p>
      </IonCardContent>
    </IonCard>
  );
};

export { OrderDetailNote };
