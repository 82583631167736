import React, { useRef, useMemo } from 'react';
import { Storage } from '@capacitor/storage';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useTranslation } from 'react-i18next';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { useToast } from './contexts/toast-context';

const endpoint1 = createUploadLink({ uri: process.env.REACT_APP_API_URL });

// TODO: change localhost, because it will not work on android device (external device)
const endpoint2 = new HttpLink({
  // uri: "https://glowing-stingray-38.hasura.app/v1/graphql", // use https for secure endpoint
  uri: 'http://localhost:8080/v1/graphql', // use https for secure endpoint
  // headers: {
  // }
});

// Create a WebSocket link:
// TODO: change localhost, because it will not work on android device (external device)
const wsLink = new WebSocketLink({
  uri: 'ws://localhost:8080/v1/graphql', // use wss for a secure endpoint
  options: {
    reconnect: true,
  },
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  split((operation) => operation.getContext().clientName === 'chat', endpoint2, endpoint1)
);

const asyncAuthLink = setContext(async (_: any, { headers }: any) => {
  // get the authentication token from local storage if it exists
  const { value: token } = await Storage.get({ key: 'token' });

  return {
    headers: {
      ...headers,
      Authorization: token ? `Token ${token}` : '',
    },
  };
});

interface Props {
  children: React.ReactNode;
}

const CustomApolloProvider = ({ children }: Props) => {
  const { dispatch } = useToast();
  const { t } = useTranslation();
  const errorLink = useMemo(
    () =>
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message }) => {
            if (message.includes('Security code is not valid')) {
              dispatch({
                type: 'DANGER',
                message: t('Invalid authorization code.'),
              });
            } else if (message.includes('User with this phone or email already exists')) {
              dispatch({
                type: 'DANGER',
                message: t('User with this phone or email already exists. Perhaps you entered them wrong.'),
              });
            } else if (message.includes('Signature has expired')) {
              window.location.replace('/');
              localStorage.removeItem('CapacitorStorage.token');
              localStorage.removeItem('CapacitorStorage.user');
              dispatch({
                type: 'DANGER',
                message: t('Signature has expired'),
              });
            } else {
              // dispatch({type: "DANGER", message: 'Something went wrong, server problem.'}) PROD
              dispatch({ type: 'DANGER', message });
            }
          });
        }
        if (networkError) {
          dispatch({ type: 'DANGER', message: `Network error: ${networkError.message}` });
        }
      }),
    [dispatch, t]
  );

  const { current: apolloClient } = useRef(
    new ApolloClient({
      link: asyncAuthLink.concat(errorLink).concat(link),
      cache: new InMemoryCache(),
    })
  );

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export { CustomApolloProvider };
