import React from 'react';
import BagCard from '../bagCard/BagCard';
import { OrdersBagTypeEdge } from '../../generated/graphql';

interface IBags {
  ordersBags: OrdersBagTypeEdge[];
}

const BagsList: React.FC<IBags> = ({ ordersBags }) => {
  return (
    <>
      {ordersBags.map((bag: OrdersBagTypeEdge) => {
        return <BagCard key={bag.node?.id} bag={bag.node!} />;
      })}
    </>
  );
};

export { BagsList };
