import React from 'react';
import { IonCheckbox, IonCol, IonItem, IonLabel, IonRow } from '@ionic/react';
import classNames from 'classnames/bind';
import { format } from 'date-fns';
import styles from './ScheduleListItem.module.scss';
import { IntervalType, useAttachDriverToIntervalMutation } from '../../../generated/graphql';

interface IScheduleListItem {
  date: string;
  item: IntervalType;
}

const cx = classNames.bind(styles);

const ScheduleListItem: React.FC<IScheduleListItem> = ({ item, date }) => {
  const [addDriverToInterval, { loading }] = useAttachDriverToIntervalMutation();

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleAddDriverToInterval = (intervalId: string, date: string) => async () => {
    await addDriverToInterval({
      variables: {
        intervalId,
        date,
      },
    });
  };

  return (
    <IonItem>
      <IonRow className={cx('row')}>
        <IonCol size="6">
          <IonRow className="ion-justify-content-around ion-text-center">
            <IonCol>
              <IonLabel>{item.start}</IonLabel>
            </IonCol>
            <IonCol className={cx('separator')}>-</IonCol>
            <IonCol>
              <IonLabel>{item.end}</IonLabel>
            </IonCol>
          </IonRow>
        </IonCol>
        <IonCol size="6">
          <IonRow>
            <IonCol className="ion-text-right">
              <IonLabel>
                {item.occupancy} / {item.capacity}
              </IonLabel>
            </IonCol>
            <IonCol>
              <IonCheckbox
                className={cx('checkbox')}
                checked={item.added}
                disabled={loading || (item.occupancy === item.capacity && !item.added)}
                onClick={handleAddDriverToInterval(item.id, date)}
              />
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </IonItem>
  );
};

export { ScheduleListItem };
