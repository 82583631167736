import React from 'react';
import { generateOrdersItems } from '../../utils/orders';
import { OrderTypeEdge } from '../../generated/graphql';
import { IonItemDivider, IonItemGroup, IonLabel } from '@ionic/react';
import { Order } from '../order/Order';
import classNames from 'classnames/bind';
import styles from './BagDetail.module.scss';

interface IBagDetail {
  orders: Array<OrderTypeEdge>;
}

const BagDetail: React.FC<IBagDetail> = ({ orders }) => {
  const cx = classNames.bind(styles);

  return (
    <IonItemGroup>
      {generateOrdersItems(orders)?.map((order: { [key: string]: any }) => {
        if (order.type && order.type === 'hour') {
          return (
            <IonItemDivider
              key={order.id}
              className={cx('ordersGroup__divider', { ordersGroup__byHour: order.type === 'hour' })}
            >
              <IonLabel className={cx('ordersGroup__title')}>{order.date}</IonLabel>
            </IonItemDivider>
          );
        }
        if (order.type !== 'day') {
          return <Order key={order.node.id} order={order.node} />;
        }
      })}
    </IonItemGroup>
  );
};

export { BagDetail as BagDetailComponent };
