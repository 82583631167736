import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonIcon, IonRow, IonCol } from '@ionic/react';
import {cash, star, bagSharp, server, reorderFour, compass} from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import styles from './StatisticsCard.module.scss';
import { DriverStatisticsType } from '../../generated/graphql';

interface IStatisticsCardProps {
  data: DriverStatisticsType;
  title: string;
}

const StatisticsCard = ({ data, title }: IStatisticsCardProps) => {
  const { t } = useTranslation();

  return (
    <IonCard className={styles.statsCard}>
      <IonCardHeader>
        <IonCardTitle>{title}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonRow className={styles.cardRow}>
          <IonCol sizeSm="1">
            <IonIcon className={styles.cardIcon} icon={reorderFour} />
          </IonCol>
          <IonCol sizeSm="5" sizeMd="6">{`${t('Order Count')}:`}</IonCol>
          <IonCol sizeSm="2" sizeMd="3">
            {data.ordersAmount?.toLocaleString()}
          </IonCol>
        </IonRow>
        <IonRow className={'ion-align-items-center'}>
          <IonCol sizeSm="1">
            <IonIcon className={styles.cardIcon} icon={bagSharp} />
          </IonCol>
          <IonCol sizeSm="5" sizeMd="6">{`${t('Bag Count')}:`}</IonCol>
          <IonCol sizeSm="2" sizeMd="3">
            {data.bagsAmount?.toLocaleString()}
          </IonCol>
        </IonRow>
        <IonRow className={'ion-align-items-center'}>
          <IonCol sizeSm="1">
            <IonIcon className={styles.cardIcon} icon={compass} />
          </IonCol>
          <IonCol sizeSm="5" sizeMd="6">{`${t('Total distance')}:`}</IonCol>
          <IonCol sizeSm="2" sizeMd="3">
            {data.totalDistance?.toLocaleString()} km
          </IonCol>
        </IonRow>
        <IonRow className={'ion-align-items-center'}>
          <IonCol sizeSm="1">
            <IonIcon className={styles.cardIcon} icon={cash} />
          </IonCol>
          <IonCol sizeSm="5" sizeMd="6">{`${t('Earned')}:`}</IonCol>
          <IonCol sizeSm="2" sizeMd="3">
            {data.driverEarnings?.toLocaleString()} ₽
          </IonCol>
        </IonRow>
        <IonRow className={'ion-align-items-center'}>
          <IonCol sizeSm="1">
            <IonIcon className={styles.cardIcon} icon={star} />
          </IonCol>
          <IonCol sizeSm="5" sizeMd="6">{`${t('Rating')}:`}</IonCol>
          <IonCol sizeSm="2" sizeMd="3">
            x
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  );
};

export { StatisticsCard };
