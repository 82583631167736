import { IonCard, IonCardContent, IonCol, IonRow } from '@ionic/react';
import { StatisticsCard } from '../StatisticsCard/StatisticsCard';
import { useTranslation } from 'react-i18next';

import styles from './DeliveryHistory.module.scss';
import {StatisticsQuery} from "../../generated/graphql";

interface Props {
  statistics: StatisticsQuery
}

const DeliveryHistoryStats = ({ statistics }: Props) => {
  const { t } = useTranslation();

  return (
    <IonCard>
      <IonCardContent className={'ion-no-padding'}>
        <IonRow className={styles.cardsWrap}>
          <IonCol sizeXs="12" sizeLg="6" className={'ion-no-padding'}>
            <StatisticsCard data={statistics.today!} title={t('Today')} />
          </IonCol>
          {/*<IonCol sizeSm="2" sizeMd="3" sizeLg="4" className={'ion-no-padding'}>*/}
          {/*  <StatisticsCard data={dummyStatsData.week} title={t('Week')} />*/}
          {/*</IonCol>*/}
          <IonCol sizeXs="12" sizeLg="6" className={'ion-no-padding'}>
            <StatisticsCard data={statistics.month!} title={t('Month')} />
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  );
};

export { DeliveryHistoryStats };
