/* eslint-disable */
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /**
   *
   * `Geometry` scalar may be represented in a few ways:
   * - Well-known text (WKT)
   * - Hexadecimal (HEX)
   * - GeoJSON
   *
   */
  Geometry: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any;
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any;
};

export type AddChangeRequestToOrder = {
  __typename?: 'AddChangeRequestToOrder';
  order?: Maybe<OrderType>;
};

export type AddDriverToBag = {
  __typename?: 'AddDriverToBag';
  bag?: Maybe<BagType>;
};

export type AddDriverToOrdersBag = {
  __typename?: 'AddDriverToOrdersBag';
  ordersBag?: Maybe<OrdersBagType>;
};

export type AddDriverToOrdersBagType = {
  bagCode: Scalars['String'];
};

export type AddOrderToBag = {
  __typename?: 'AddOrderToBag';
  bag?: Maybe<BagType>;
  order?: Maybe<OrderType>;
};

export type AddressSuggestionResultType = {
  __typename?: 'AddressSuggestionResultType';
  fullAddress?: Maybe<Scalars['String']>;
  location?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type AddressType = {
  __typename?: 'AddressType';
  id: Scalars['ID'];
  city: Scalars['String'];
  street: Scalars['String'];
  house: Scalars['String'];
  entrance: Scalars['String'];
  floor: Scalars['String'];
  flat: Scalars['String'];
  location?: Maybe<GeometryObjectType>;
  fullAddress: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  orderSet: OrderTypeConnection;
};


export type AddressTypeOrderSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AppointPickerForOrder = {
  __typename?: 'AppointPickerForOrder';
  order?: Maybe<OrderType>;
};

export type AttachDriverToInterval = {
  __typename?: 'AttachDriverToInterval';
  interval?: Maybe<IntervalType>;
};

/** An enumeration. */
export enum BagStatus {
  /** EMPTY */
  A_1 = 'A_1',
  /** WAITING_DELIVERY */
  A_2 = 'A_2',
  /** ON_DELIVERY */
  A_3 = 'A_3'
}

export type BagType = {
  __typename?: 'BagType';
  id: Scalars['ID'];
  code: Scalars['String'];
  status: BagStatus;
  ordersbagSet: OrdersBagTypeConnection;
  activeOrdersBag?: Maybe<OrdersBagType>;
};


export type BagTypeOrdersbagSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BagTypeConnection = {
  __typename?: 'BagTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BagTypeEdge>>;
};

/** A Relay edge containing a `BagType` and its cursor. */
export type BagTypeEdge = {
  __typename?: 'BagTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<BagType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ChangeOrderDeliveryStatus = {
  __typename?: 'ChangeOrderDeliveryStatus';
  order?: Maybe<OrderType>;
};

export type ChangeOrderDeliveryStatusType = {
  orderId: Scalars['ID'];
  deliveryStatus: OrderDeliveryStatus;
};

export type CreateCustomerAddress = {
  __typename?: 'CreateCustomerAddress';
  address?: Maybe<CustomerAddressType>;
};

export type CreateDriver = {
  __typename?: 'CreateDriver';
  driver?: Maybe<DriverType>;
};

export type CreateOperator = {
  __typename?: 'CreateOperator';
  user?: Maybe<UserType>;
};

export type CreatePicker = {
  __typename?: 'CreatePicker';
  picker?: Maybe<PickerType>;
};

export type CreateReview = {
  __typename?: 'CreateReview';
  review?: Maybe<ReviewType>;
};

export type CreateReviewInputType = {
  review?: Maybe<Scalars['String']>;
};

/**
 * Mutation that receives 'phone' and 'security_code'
 * and returns a jwt token
 */
export type CreateToken = {
  __typename?: 'CreateToken';
  user?: Maybe<UserType>;
  token?: Maybe<Scalars['String']>;
};

export type CustomerAddressInput = {
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  isFlat?: Maybe<Scalars['Boolean']>;
  entrance?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  apartmentNumber?: Maybe<Scalars['String']>;
  intercom?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
};

export type CustomerAddressType = {
  __typename?: 'CustomerAddressType';
  id: Scalars['ID'];
  customerId: Scalars['Int'];
  state: Scalars['String'];
  city: Scalars['String'];
  street: Scalars['String'];
  house: Scalars['String'];
  isFlat: Scalars['Boolean'];
  entrance: Scalars['String'];
  floor: Scalars['String'];
  apartmentNumber: Scalars['String'];
  intercom: Scalars['String'];
  location?: Maybe<GeometryObjectType>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CustomerAddressTypeConnection = {
  __typename?: 'CustomerAddressTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerAddressTypeEdge>>;
};

/** A Relay edge containing a `CustomerAddressType` and its cursor. */
export type CustomerAddressTypeEdge = {
  __typename?: 'CustomerAddressTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CustomerAddressType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};




export type DeleteCustomerAddress = {
  __typename?: 'DeleteCustomerAddress';
  addressId?: Maybe<Scalars['ID']>;
};

export type DeleteDriver = {
  __typename?: 'DeleteDriver';
  driverId?: Maybe<Scalars['ID']>;
};

export type DeleteOperator = {
  __typename?: 'DeleteOperator';
  operatorId?: Maybe<Scalars['ID']>;
};

export type DeletePicker = {
  __typename?: 'DeletePicker';
  pickerId?: Maybe<Scalars['ID']>;
};

export type DriverStatisticsType = {
  __typename?: 'DriverStatisticsType';
  ordersAmount?: Maybe<Scalars['Int']>;
  bagsAmount?: Maybe<Scalars['Int']>;
  ordersTotalCost?: Maybe<Scalars['Float']>;
  driverEarnings?: Maybe<Scalars['Float']>;
  totalDistance?: Maybe<Scalars['Float']>;
};

/** An enumeration. */
export enum DriverStatus {
  /** PENDING */
  Pending = 'PENDING',
  /** ACTIVE */
  Active = 'ACTIVE',
  /** BANNED */
  Banned = 'BANNED',
  /** ARCHIVED */
  Archived = 'ARCHIVED'
}

export type DriverType = {
  __typename?: 'DriverType';
  id: Scalars['ID'];
  user: UserType;
  vehicle?: Maybe<VehicleType>;
  status: DriverStatus;
  ordersbagSet: OrdersBagTypeConnection;
  driverIntervals: Array<IntervalDriverType>;
};


export type DriverTypeOrdersbagSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DriverTypeConnection = {
  __typename?: 'DriverTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DriverTypeEdge>>;
};

/** A Relay edge containing a `DriverType` and its cursor. */
export type DriverTypeEdge = {
  __typename?: 'DriverTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<DriverType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type EvaluatePicker = {
  __typename?: 'EvaluatePicker';
  order?: Maybe<OrderType>;
};

export type FinishOrderWithChangeRequest = {
  __typename?: 'FinishOrderWithChangeRequest';
  order?: Maybe<OrderType>;
};

export type ForeignKeyInput = {
  id?: Maybe<Scalars['ID']>;
};



/**
 * `GeometryObjectType` represents a pair of values:
 * - Geometry `type`
 * - Geometry `coordinates`
 */
export type GeometryObjectType = {
  __typename?: 'GeometryObjectType';
  type?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Scalars['GenericScalar']>;
};

export type GetSberPaymentsFormLink = {
  __typename?: 'GetSberPaymentsFormLink';
  payment?: Maybe<OrderPaymentType>;
};

export type ImageType = {
  __typename?: 'ImageType';
  id: Scalars['ID'];
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  userSet: UserTypeConnection;
};


export type ImageTypeUserSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type IntervalDriverType = {
  __typename?: 'IntervalDriverType';
  id: Scalars['ID'];
  interval: IntervalType;
  driver: DriverType;
  date: Scalars['Date'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type IntervalType = {
  __typename?: 'IntervalType';
  id: Scalars['ID'];
  start: Scalars['Time'];
  end: Scalars['Time'];
  capacity: Scalars['Int'];
  date: Scalars['Date'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  intervalDrivers: Array<IntervalDriverType>;
  added?: Maybe<Scalars['Boolean']>;
  occupancy?: Maybe<Scalars['Int']>;
};

export type IntervalTypeConnection = {
  __typename?: 'IntervalTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IntervalTypeEdge>>;
};

/** A Relay edge containing a `IntervalType` and its cursor. */
export type IntervalTypeEdge = {
  __typename?: 'IntervalTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<IntervalType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};


export type LoginInputType = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MessageType = {
  __typename?: 'MessageType';
  id: Scalars['ID'];
  title: Scalars['String'];
  text: Scalars['String'];
  author: UserType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MessageTypeConnection = {
  __typename?: 'MessageTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MessageTypeEdge>>;
};

/** A Relay edge containing a `MessageType` and its cursor. */
export type MessageTypeEdge = {
  __typename?: 'MessageTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<MessageType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createDriver?: Maybe<CreateDriver>;
  updateDriver?: Maybe<UpdateDriver>;
  deleteDriver?: Maybe<DeleteDriver>;
  createPicker?: Maybe<CreatePicker>;
  updatePicker?: Maybe<UpdatePicker>;
  deletePicker?: Maybe<DeletePicker>;
  createCustomerAddress?: Maybe<CreateCustomerAddress>;
  updateCustomerAddress?: Maybe<UpdateCustomerAddress>;
  deleteCustomerAddress?: Maybe<DeleteCustomerAddress>;
  changeOrderDeliveryStatus?: Maybe<ChangeOrderDeliveryStatus>;
  updateOrder?: Maybe<UpdateOrder>;
  updateBag?: Maybe<UpdateBag>;
  updateOrdersBag?: Maybe<UpdateOrdersBag>;
  addDriverToOrdersBag?: Maybe<AddDriverToOrdersBag>;
  addChangeRequestToOrder?: Maybe<AddChangeRequestToOrder>;
  addOrderToBag?: Maybe<AddOrderToBag>;
  addDriverToBag?: Maybe<AddDriverToBag>;
  removeOrderFromBag?: Maybe<RemoveOrderFromBag>;
  finishOrderWithChangeRequest?: Maybe<FinishOrderWithChangeRequest>;
  getSberPaymentsFormLink?: Maybe<GetSberPaymentsFormLink>;
  attachDriverToInterval?: Maybe<AttachDriverToInterval>;
  swapOrderPriority?: Maybe<SwapOrderPriority>;
  appointPickerForOrder?: Maybe<AppointPickerForOrder>;
  updateOrderPaymentStatus?: Maybe<UpdateOrderPaymentStatus>;
  evaulatePicker?: Maybe<EvaluatePicker>;
  /**
   * Mutation that receives 'phone', 'email' and possibly 'role', creates a user (if doesn't exist)
   * with provided data and send security code through sms to the provided phone number
   */
  userRegister?: Maybe<UserRegister>;
  removeUser?: Maybe<RemoveUser>;
  userDataSet?: Maybe<UserData>;
  /**
   * Mutation that receives 'phone' and 'security_code'
   * and returns a jwt token
   */
  tokenAuth?: Maybe<CreateToken>;
  createReview?: Maybe<CreateReview>;
  uploadUserAvatar?: Maybe<UploadUserAvatar>;
  removeUserAvatar?: Maybe<RemoveUserAvatar>;
  verifyToken?: Maybe<Verify>;
  refreshToken?: Maybe<Refresh>;
  createOperator?: Maybe<CreateOperator>;
  updateOperator?: Maybe<UpdateOperator>;
  deleteOperator?: Maybe<DeleteOperator>;
};


export type MutationCreateDriverArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  password: Scalars['String'];
  secondName: Scalars['String'];
  vehicle?: Maybe<VehicleInputType>;
};


export type MutationUpdateDriverArgs = {
  driverId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  secondName?: Maybe<Scalars['String']>;
  vehicle?: Maybe<VehicleInputType>;
};


export type MutationDeleteDriverArgs = {
  driverId: Scalars['ID'];
};


export type MutationCreatePickerArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  password: Scalars['String'];
  secondName: Scalars['String'];
};


export type MutationUpdatePickerArgs = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  pickerId: Scalars['ID'];
  secondName?: Maybe<Scalars['String']>;
};


export type MutationDeletePickerArgs = {
  pickerId: Scalars['ID'];
};


export type MutationCreateCustomerAddressArgs = {
  address: CustomerAddressInput;
};


export type MutationUpdateCustomerAddressArgs = {
  address: CustomerAddressInput;
  addressId: Scalars['ID'];
};


export type MutationDeleteCustomerAddressArgs = {
  addressId: Scalars['ID'];
};


export type MutationChangeOrderDeliveryStatusArgs = {
  input: ChangeOrderDeliveryStatusType;
};


export type MutationUpdateOrderArgs = {
  input: UpdateOrderType;
};


export type MutationUpdateBagArgs = {
  input: UpdateBagType;
};


export type MutationUpdateOrdersBagArgs = {
  input: UpdateOrdersBagType;
};


export type MutationAddDriverToOrdersBagArgs = {
  input: AddDriverToOrdersBagType;
};


export type MutationAddChangeRequestToOrderArgs = {
  changePaymentInfo: Scalars['String'];
  customerPaidAmount: Scalars['Float'];
  orderId: Scalars['ID'];
};


export type MutationAddOrderToBagArgs = {
  bagId: Scalars['ID'];
  orderId: Scalars['ID'];
};


export type MutationAddDriverToBagArgs = {
  bagId: Scalars['ID'];
  driverId?: Maybe<Scalars['ID']>;
};


export type MutationRemoveOrderFromBagArgs = {
  orderId: Scalars['ID'];
};


export type MutationFinishOrderWithChangeRequestArgs = {
  orderId: Scalars['ID'];
};


export type MutationGetSberPaymentsFormLinkArgs = {
  orderNumber: Scalars['String'];
  paymentAmount: Scalars['Float'];
};


export type MutationAttachDriverToIntervalArgs = {
  date: Scalars['Date'];
  intervalId: Scalars['ID'];
};


export type MutationSwapOrderPriorityArgs = {
  order1: Scalars['ID'];
  order2: Scalars['ID'];
};


export type MutationAppointPickerForOrderArgs = {
  orderNumber: Scalars['ID'];
  pickerId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateOrderPaymentStatusArgs = {
  paymentId: Scalars['ID'];
  status: OrderPaymentStatus;
};


export type MutationEvaulatePickerArgs = {
  orderId: Scalars['ID'];
  rating: Scalars['Int'];
};


export type MutationUserRegisterArgs = {
  input: UserRegisterInputType;
};


export type MutationRemoveUserArgs = {
  input: RemoveUserType;
};


export type MutationUserDataSetArgs = {
  input?: Maybe<UserDataInputType>;
};


export type MutationTokenAuthArgs = {
  input: LoginInputType;
};


export type MutationCreateReviewArgs = {
  input: CreateReviewInputType;
};


export type MutationUploadUserAvatarArgs = {
  input: UploadUserAvatarType;
};


export type MutationRemoveUserAvatarArgs = {
  input: RemoveUserAvatarType;
};


export type MutationVerifyTokenArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationRefreshTokenArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationCreateOperatorArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  password: Scalars['String'];
  secondName: Scalars['String'];
};


export type MutationUpdateOperatorArgs = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  operatorId: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
  secondName?: Maybe<Scalars['String']>;
};


export type MutationDeleteOperatorArgs = {
  operatorId: Scalars['ID'];
};

/** An enumeration. */
export enum OrderDeliveryStatus {
  /** PENDING */
  A_1 = 'A_1',
  /** PROCESSING */
  A_2 = 'A_2',
  /** COMPLETED */
  A_3 = 'A_3',
  /** FAILED */
  A_4 = 'A_4',
  /** WAITING_FOR_CHANGE */
  A_5 = 'A_5'
}

export type OrderInBagType = {
  __typename?: 'OrderInBagType';
  id: Scalars['ID'];
  order: OrderType;
  ordersBag: OrdersBagType;
  priority: Scalars['Int'];
};

export type OrderInBagTypeConnection = {
  __typename?: 'OrderInBagTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderInBagTypeEdge>>;
};

/** A Relay edge containing a `OrderInBagType` and its cursor. */
export type OrderInBagTypeEdge = {
  __typename?: 'OrderInBagTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<OrderInBagType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type OrderLineItemType = {
  __typename?: 'OrderLineItemType';
  id: Scalars['ID'];
  order: OrderType;
  name: Scalars['String'];
  productId: Scalars['Int'];
  variationId?: Maybe<Scalars['Int']>;
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  totalCost: Scalars['Float'];
  subtotalCost: Scalars['Float'];
  sku: Scalars['String'];
  imageSrc?: Maybe<Scalars['String']>;
};

export type OrderLineItemTypeConnection = {
  __typename?: 'OrderLineItemTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderLineItemTypeEdge>>;
};

/** A Relay edge containing a `OrderLineItemType` and its cursor. */
export type OrderLineItemTypeEdge = {
  __typename?: 'OrderLineItemTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<OrderLineItemType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum OrderOrderStatus {
  /** PENDING */
  Pending = 'PENDING',
  /** PROCESSING */
  Processing = 'PROCESSING',
  /** ON_HOLD */
  OnHold = 'ON_HOLD',
  /** COMPLETED */
  Completed = 'COMPLETED',
  /** CANCELLED */
  Cancelled = 'CANCELLED',
  /** REFUNDED */
  Refunded = 'REFUNDED',
  /** FAILED */
  Failed = 'FAILED',
  /** TRASH */
  Trash = 'TRASH'
}

/** An enumeration. */
export enum OrderPaymentStatus {
  /** WAITING_PAYMENT */
  WaitingPayment = 'WAITING_PAYMENT',
  /** PAYED */
  Payed = 'PAYED',
  /** FAILED */
  Failed = 'FAILED'
}

export type OrderPaymentType = {
  __typename?: 'OrderPaymentType';
  id: Scalars['ID'];
  order: OrderType;
  status: OrderPaymentStatus;
  amount: Scalars['Decimal'];
  formUrl?: Maybe<Scalars['String']>;
  payedAt?: Maybe<Scalars['DateTime']>;
  additionalData: Scalars['JSONString'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type OrderPaymentTypeConnection = {
  __typename?: 'OrderPaymentTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderPaymentTypeEdge>>;
};

/** A Relay edge containing a `OrderPaymentType` and its cursor. */
export type OrderPaymentTypeEdge = {
  __typename?: 'OrderPaymentTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<OrderPaymentType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type OrderType = {
  __typename?: 'OrderType';
  id: Scalars['ID'];
  orderNumber: Scalars['Int'];
  orderStatus: OrderOrderStatus;
  shippingCost: Scalars['Float'];
  totalCost: Scalars['Float'];
  customerId: Scalars['Int'];
  customerFullname: Scalars['String'];
  customerPhone: Scalars['String'];
  address: AddressType;
  paymentMethod: PaymentMethodType;
  customerNote: Scalars['String'];
  deliveryStatus: OrderDeliveryStatus;
  deliveryDate: Scalars['Date'];
  deliveryIntervalStart: Scalars['Time'];
  deliveryIntervalEnd: Scalars['Time'];
  ordersBagDeprecated?: Maybe<OrdersBagType>;
  shippingMethod?: Maybe<ShippingMethodType>;
  changeAmount: Scalars['Float'];
  changePaymentInfo: Scalars['String'];
  placedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  orderinbagSet: OrderInBagTypeConnection;
  lineItems: OrderLineItemTypeConnection;
  assemblies: PickerOrderAssemblyTypeConnection;
  orderpaymentSet: OrderPaymentTypeConnection;
  orderinbag?: Maybe<OrderInBagType>;
  activeAssembly?: Maybe<PickerOrderAssemblyType>;
};


export type OrderTypeOrderinbagSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderTypeLineItemsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderTypeAssembliesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderTypeOrderpaymentSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type OrderTypeConnection = {
  __typename?: 'OrderTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderTypeEdge>>;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `OrderType` and its cursor. */
export type OrderTypeEdge = {
  __typename?: 'OrderTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<OrderType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum OrdersBagStatus {
  /** PENDING */
  Pending = 'PENDING',
  /** IN_PROGRESS */
  InProgress = 'IN_PROGRESS',
  /** FINISHED */
  Finished = 'FINISHED'
}

export type OrdersBagType = {
  __typename?: 'OrdersBagType';
  id: Scalars['ID'];
  bag?: Maybe<BagType>;
  driver?: Maybe<DriverType>;
  weight: Scalars['Float'];
  status: OrdersBagStatus;
  takenAt?: Maybe<Scalars['DateTime']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  orderinbagSet: OrderInBagTypeConnection;
  orderSet: OrderTypeConnection;
  orders?: Maybe<Array<Maybe<OrderType>>>;
  validDrivers?: Maybe<Array<Maybe<DriverType>>>;
};


export type OrdersBagTypeOrderinbagSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrdersBagTypeOrderSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type OrdersBagTypeConnection = {
  __typename?: 'OrdersBagTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrdersBagTypeEdge>>;
};

/** A Relay edge containing a `OrdersBagType` and its cursor. */
export type OrdersBagTypeEdge = {
  __typename?: 'OrdersBagTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<OrdersBagType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type PaymentMethodType = {
  __typename?: 'PaymentMethodType';
  id: Scalars['ID'];
  code: Scalars['String'];
  title: Scalars['String'];
  orderSet: OrderTypeConnection;
};


export type PaymentMethodTypeOrderSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PaymentMethodTypeConnection = {
  __typename?: 'PaymentMethodTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaymentMethodTypeEdge>>;
};

/** A Relay edge containing a `PaymentMethodType` and its cursor. */
export type PaymentMethodTypeEdge = {
  __typename?: 'PaymentMethodTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PaymentMethodType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum PickerOrderAssemblyStatus {
  /** PENDING */
  Pending = 'PENDING',
  /** IN_PROGRESS */
  InProgress = 'IN_PROGRESS',
  /** FINISHED */
  Finished = 'FINISHED'
}

export type PickerOrderAssemblyType = {
  __typename?: 'PickerOrderAssemblyType';
  id: Scalars['ID'];
  order: OrderType;
  picker?: Maybe<PickerType>;
  rating?: Maybe<Scalars['Int']>;
  status: PickerOrderAssemblyStatus;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PickerOrderAssemblyTypeConnection = {
  __typename?: 'PickerOrderAssemblyTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PickerOrderAssemblyTypeEdge>>;
};

/** A Relay edge containing a `PickerOrderAssemblyType` and its cursor. */
export type PickerOrderAssemblyTypeEdge = {
  __typename?: 'PickerOrderAssemblyTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PickerOrderAssemblyType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PickerType = {
  __typename?: 'PickerType';
  id: Scalars['ID'];
  user: UserType;
  assemblies: PickerOrderAssemblyTypeConnection;
};


export type PickerTypeAssembliesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PickerTypeConnection = {
  __typename?: 'PickerTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PickerTypeEdge>>;
};

/** A Relay edge containing a `PickerType` and its cursor. */
export type PickerTypeEdge = {
  __typename?: 'PickerTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PickerType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  customerAddresses?: Maybe<CustomerAddressTypeConnection>;
  driverStatistics?: Maybe<DriverStatisticsType>;
  dayIntervals?: Maybe<IntervalTypeConnection>;
  orderPayment?: Maybe<OrderPaymentType>;
  orderPayments?: Maybe<OrderPaymentTypeConnection>;
  pickerOrderAssemblies?: Maybe<PickerOrderAssemblyTypeConnection>;
  pickerOrderAssembly?: Maybe<PickerOrderAssemblyType>;
  pickers?: Maybe<PickerTypeConnection>;
  picker?: Maybe<PickerType>;
  driverHistoryOrders?: Maybe<OrderTypeConnection>;
  driverActiveOrders?: Maybe<OrderTypeConnection>;
  bag?: Maybe<BagType>;
  bags?: Maybe<BagTypeConnection>;
  ordersBag?: Maybe<OrdersBagType>;
  ordersBags?: Maybe<OrdersBagTypeConnection>;
  shippingMethod?: Maybe<ShippingMethodType>;
  shippingMethods?: Maybe<ShippingMethodTypeConnection>;
  paymentMethod?: Maybe<PaymentMethodType>;
  paymentMethods?: Maybe<PaymentMethodTypeConnection>;
  driver?: Maybe<DriverType>;
  drivers?: Maybe<DriverTypeConnection>;
  order?: Maybe<OrderType>;
  boardOrders?: Maybe<OrderTypeConnection>;
  orders?: Maybe<OrderTypeConnection>;
  setting?: Maybe<SettingType>;
  operatorUsers?: Maybe<UserTypeConnection>;
  version?: Maybe<VersionType>;
  geocode?: Maybe<AddressType>;
  addressSuggestions?: Maybe<Array<Maybe<AddressSuggestionResultType>>>;
  users?: Maybe<UserTypeConnection>;
  user?: Maybe<UserType>;
  me?: Maybe<UserType>;
};


export type QueryCustomerAddressesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDriverStatisticsArgs = {
  driverId?: Maybe<Scalars['ID']>;
  period?: Maybe<StatisticsPeriodEnum>;
};


export type QueryDayIntervalsArgs = {
  date?: Maybe<Scalars['Date']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrderPaymentArgs = {
  id: Scalars['ID'];
};


export type QueryOrderPaymentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPickerOrderAssembliesArgs = {
  orderNumber?: Maybe<Scalars['ID']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPickerOrderAssemblyArgs = {
  id: Scalars['ID'];
};


export type QueryPickersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPickerArgs = {
  id: Scalars['ID'];
};


export type QueryDriverHistoryOrdersArgs = {
  ordersBag?: Maybe<Scalars['ID']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDriverActiveOrdersArgs = {
  ordersBag?: Maybe<Scalars['ID']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryBagArgs = {
  id: Scalars['ID'];
};


export type QueryBagsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrdersBagArgs = {
  id: Scalars['ID'];
};


export type QueryOrdersBagsArgs = {
  status?: Maybe<Array<Maybe<OrdersBagStatus>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryShippingMethodArgs = {
  id: Scalars['ID'];
};


export type QueryShippingMethodsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPaymentMethodArgs = {
  id: Scalars['ID'];
};


export type QueryPaymentMethodsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDriverArgs = {
  id: Scalars['ID'];
};


export type QueryDriversArgs = {
  status?: Maybe<Array<Maybe<DriverStatus>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryBoardOrdersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrdersArgs = {
  ordersBag?: Maybe<Scalars['ID']>;
  status?: Maybe<Array<Maybe<OrderDeliveryStatus>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySettingArgs = {
  name: Scalars['String'];
};


export type QueryOperatorUsersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGeocodeArgs = {
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};


export type QueryAddressSuggestionsArgs = {
  addressStr: Scalars['String'];
};


export type QueryUsersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type RemoveOrderFromBag = {
  __typename?: 'RemoveOrderFromBag';
  bag?: Maybe<BagType>;
  order?: Maybe<OrderType>;
};

export type RemoveUser = {
  __typename?: 'RemoveUser';
  success?: Maybe<Scalars['Boolean']>;
};

export type RemoveUserAvatar = {
  __typename?: 'RemoveUserAvatar';
  success?: Maybe<Scalars['Boolean']>;
};

export type RemoveUserAvatarType = {
  avatarId: Scalars['ID'];
};

export type RemoveUserType = {
  userId: Scalars['ID'];
};

export type ReviewType = {
  __typename?: 'ReviewType';
  id: Scalars['ID'];
  author: UserType;
  review: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ReviewTypeConnection = {
  __typename?: 'ReviewTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReviewTypeEdge>>;
};

/** A Relay edge containing a `ReviewType` and its cursor. */
export type ReviewTypeEdge = {
  __typename?: 'ReviewTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ReviewType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type SettingType = {
  __typename?: 'SettingType';
  id: Scalars['ID'];
  name: Scalars['String'];
  data: Scalars['JSONString'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ShippingMethodType = {
  __typename?: 'ShippingMethodType';
  id: Scalars['ID'];
  code: Scalars['String'];
  title: Scalars['String'];
  orderSet: OrderTypeConnection;
};


export type ShippingMethodTypeOrderSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ShippingMethodTypeConnection = {
  __typename?: 'ShippingMethodTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ShippingMethodTypeEdge>>;
};

/** A Relay edge containing a `ShippingMethodType` and its cursor. */
export type ShippingMethodTypeEdge = {
  __typename?: 'ShippingMethodTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ShippingMethodType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum StatisticsPeriodEnum {
  Today = 'today',
  Week = 'week',
  Month = 'month'
}

export type SwapOrderPriority = {
  __typename?: 'SwapOrderPriority';
  bag?: Maybe<BagType>;
};


export type UpdateBag = {
  __typename?: 'UpdateBag';
  bag?: Maybe<BagType>;
};

export type UpdateBagType = {
  bagId: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
};

export type UpdateCustomerAddress = {
  __typename?: 'UpdateCustomerAddress';
  address?: Maybe<CustomerAddressType>;
};

export type UpdateDriver = {
  __typename?: 'UpdateDriver';
  driver?: Maybe<DriverType>;
};

export type UpdateOperator = {
  __typename?: 'UpdateOperator';
  user?: Maybe<UserType>;
};

export type UpdateOrder = {
  __typename?: 'UpdateOrder';
  order?: Maybe<OrderType>;
};

export type UpdateOrderPaymentStatus = {
  __typename?: 'UpdateOrderPaymentStatus';
  payment?: Maybe<OrderPaymentType>;
};

export type UpdateOrderType = {
  id?: Maybe<Scalars['ID']>;
  deliveryStatus?: Maybe<OrderDeliveryStatus>;
  orderStatus?: Maybe<OrderOrderStatus>;
  customerFullname?: Maybe<Scalars['String']>;
  customerPhone?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['String']>;
  deliveryIntervalStart?: Maybe<Scalars['String']>;
  deliveryIntervalEnd?: Maybe<Scalars['String']>;
  shippingCost?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  shippingMethod?: Maybe<ForeignKeyInput>;
  paymentMethod?: Maybe<ForeignKeyInput>;
};

export type UpdateOrdersBag = {
  __typename?: 'UpdateOrdersBag';
  ordersBag?: Maybe<OrdersBagType>;
};

export type UpdateOrdersBagType = {
  ordersBagId: Scalars['ID'];
  bagId?: Maybe<Scalars['ID']>;
  driverId?: Maybe<Scalars['ID']>;
  weight?: Maybe<Scalars['Float']>;
  takenAt?: Maybe<Scalars['DateTime']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdatePicker = {
  __typename?: 'UpdatePicker';
  picker?: Maybe<PickerType>;
};


export type UploadUserAvatar = {
  __typename?: 'UploadUserAvatar';
  image?: Maybe<ImageType>;
};

export type UploadUserAvatarType = {
  userId: Scalars['ID'];
  file: Scalars['Upload'];
};

export type UserData = {
  __typename?: 'UserData';
  user?: Maybe<UserType>;
};

export type UserDataInputType = {
  firstName: Scalars['String'];
  secondName: Scalars['String'];
  thirdName: Scalars['String'];
  dateOfBirth: Scalars['DateTime'];
  address: Scalars['String'];
  location: Scalars['Geometry'];
  diagnosis: Scalars['String'];
  menstrualCorrelation: Scalars['Boolean'];
};

/**
 * Mutation that receives 'phone', 'email' and possibly 'role', creates a user (if doesn't exist)
 * with provided data and send security code through sms to the provided phone number
 */
export type UserRegister = {
  __typename?: 'UserRegister';
  user?: Maybe<UserType>;
};

export type UserRegisterInputType = {
  phone: Scalars['String'];
  email: Scalars['String'];
  role?: Maybe<UserRole>;
};

/** An enumeration. */
export enum UserRole {
  /** admin */
  A_1 = 'A_1',
  /** operator */
  A_2 = 'A_2',
  /** driver */
  A_3 = 'A_3',
  /** client */
  A_4 = 'A_4',
  /** picker */
  A_5 = 'A_5'
}

export type UserType = {
  __typename?: 'UserType';
  id: Scalars['ID'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** Указывает, что пользователь имеет все права без явного их назначения. */
  isSuperuser: Scalars['Boolean'];
  lastName: Scalars['String'];
  /** Отметьте, если пользователь может входить в административную часть сайта. */
  isStaff: Scalars['Boolean'];
  /** Отметьте, если пользователь должен считаться активным. Уберите эту отметку вместо удаления учётной записи. */
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  secondName: Scalars['String'];
  role: UserRole;
  avatar?: Maybe<ImageType>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  messageSet: MessageTypeConnection;
  reviewSet: ReviewTypeConnection;
  driverSet: DriverTypeConnection;
  pickerSet: PickerTypeConnection;
  roleName?: Maybe<Scalars['String']>;
};


export type UserTypeMessageSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserTypeReviewSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserTypeDriverSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserTypePickerSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserTypeConnection = {
  __typename?: 'UserTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserTypeEdge>>;
};

/** A Relay edge containing a `UserType` and its cursor. */
export type UserTypeEdge = {
  __typename?: 'UserTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<UserType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type VehicleInputType = {
  name?: Maybe<Scalars['String']>;
  vehicleType?: Maybe<VehicleVehicleType>;
  licensePlate?: Maybe<Scalars['String']>;
};

export type VehicleType = {
  __typename?: 'VehicleType';
  id: Scalars['ID'];
  name: Scalars['String'];
  vehicleType: VehicleVehicleType;
  licensePlate?: Maybe<Scalars['String']>;
  drivers: DriverTypeConnection;
};


export type VehicleTypeDriversArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum VehicleVehicleType {
  /** CAR */
  A_1 = 'A_1',
  /** BICYCLE */
  A_2 = 'A_2',
  /** MOPED */
  A_3 = 'A_3',
  /** MOTORCYCLE */
  A_4 = 'A_4',
  /** KICK_SCOOTER */
  A_5 = 'A_5'
}

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type VersionType = {
  __typename?: 'VersionType';
  versionIos?: Maybe<Scalars['String']>;
  versionAndroid?: Maybe<Scalars['String']>;
};

export type OrdersBagsQueryVariables = Exact<{
  status?: Maybe<Array<Maybe<OrdersBagStatus>> | Maybe<OrdersBagStatus>>;
}>;


export type OrdersBagsQuery = (
  { __typename?: 'Query' }
  & { ordersBags?: Maybe<(
    { __typename?: 'OrdersBagTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'OrdersBagTypeEdge' }
      & { node?: Maybe<(
        { __typename?: 'OrdersBagType' }
        & Pick<OrdersBagType, 'id' | 'weight' | 'takenAt' | 'finishedAt' | 'createdAt'>
        & { bag?: Maybe<(
          { __typename?: 'BagType' }
          & Pick<BagType, 'id' | 'code' | 'status'>
        )>, orders?: Maybe<Array<Maybe<(
          { __typename?: 'OrderType' }
          & Pick<OrderType, 'totalCost'>
          & { lineItems: (
            { __typename?: 'OrderLineItemTypeConnection' }
            & { edges: Array<Maybe<(
              { __typename?: 'OrderLineItemTypeEdge' }
              & { node?: Maybe<(
                { __typename?: 'OrderLineItemType' }
                & Pick<OrderLineItemType, 'id'>
              )> }
            )>> }
          ) }
        )>>>, driver?: Maybe<(
          { __typename?: 'DriverType' }
          & { vehicle?: Maybe<(
            { __typename?: 'VehicleType' }
            & Pick<VehicleType, 'vehicleType' | 'licensePlate'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type OrdersBagQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrdersBagQuery = (
  { __typename?: 'Query' }
  & { ordersBag?: Maybe<(
    { __typename?: 'OrdersBagType' }
    & Pick<OrdersBagType, 'id' | 'weight' | 'takenAt' | 'finishedAt' | 'createdAt'>
    & { bag?: Maybe<(
      { __typename?: 'BagType' }
      & Pick<BagType, 'id' | 'status' | 'code'>
    )>, orders?: Maybe<Array<Maybe<(
      { __typename?: 'OrderType' }
      & Pick<OrderType, 'id' | 'orderNumber' | 'orderStatus' | 'shippingCost' | 'totalCost' | 'customerId' | 'customerPhone' | 'customerFullname' | 'placedAt' | 'createdAt' | 'updatedAt' | 'customerNote' | 'deliveryStatus' | 'deliveryDate' | 'deliveryIntervalStart' | 'deliveryIntervalEnd'>
      & { address: (
        { __typename?: 'AddressType' }
        & Pick<AddressType, 'city' | 'street' | 'house' | 'entrance' | 'floor' | 'flat' | 'fullAddress'>
        & { location?: Maybe<(
          { __typename?: 'GeometryObjectType' }
          & Pick<GeometryObjectType, 'coordinates'>
        )> }
      ), paymentMethod: (
        { __typename?: 'PaymentMethodType' }
        & Pick<PaymentMethodType, 'code' | 'title'>
      ), shippingMethod?: Maybe<(
        { __typename?: 'ShippingMethodType' }
        & Pick<ShippingMethodType, 'code' | 'title'>
      )>, lineItems: (
        { __typename?: 'OrderLineItemTypeConnection' }
        & { edges: Array<Maybe<(
          { __typename?: 'OrderLineItemTypeEdge' }
          & { node?: Maybe<(
            { __typename?: 'OrderLineItemType' }
            & Pick<OrderLineItemType, 'name' | 'productId' | 'variationId' | 'quantity' | 'price' | 'totalCost' | 'subtotalCost' | 'sku'>
          )> }
        )>> }
      ) }
    )>>>, driver?: Maybe<(
      { __typename?: 'DriverType' }
      & Pick<DriverType, 'id'>
      & { vehicle?: Maybe<(
        { __typename?: 'VehicleType' }
        & Pick<VehicleType, 'vehicleType'>
      )> }
    )> }
  )> }
);

export type AddDriverToOrdersBagMutationVariables = Exact<{
  input: AddDriverToOrdersBagType;
}>;


export type AddDriverToOrdersBagMutation = (
  { __typename?: 'Mutation' }
  & { addDriverToOrdersBag?: Maybe<(
    { __typename?: 'AddDriverToOrdersBag' }
    & { ordersBag?: Maybe<(
      { __typename?: 'OrdersBagType' }
      & Pick<OrdersBagType, 'id'>
    )> }
  )> }
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<(
    { __typename?: 'UserTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'UserTypeEdge' }
      & { node?: Maybe<(
        { __typename?: 'UserType' }
        & Pick<UserType, 'id' | 'email' | 'phone' | 'createdAt'>
      )> }
    )>> }
  )> }
);

export type TokenAuthMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type TokenAuthMutation = (
  { __typename?: 'Mutation' }
  & { tokenAuth?: Maybe<(
    { __typename?: 'CreateToken' }
    & Pick<CreateToken, 'token'>
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'email' | 'phone' | 'firstName' | 'secondName' | 'role' | 'roleName'>
    )> }
  )> }
);

export type UserRegisterMutationVariables = Exact<{
  email: Scalars['String'];
  phone: Scalars['String'];
  role?: Maybe<UserRole>;
}>;


export type UserRegisterMutation = (
  { __typename?: 'Mutation' }
  & { userRegister?: Maybe<(
    { __typename?: 'UserRegister' }
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'phone' | 'email' | 'role'>
    )> }
  )> }
);
export type DriverStatisticsQueryVariables = Exact<{
  driverId?: Maybe<Scalars['ID']>;
  period?: Maybe<StatisticsPeriodEnum>;
}>;


export type DriverStatisticsQuery = (
  { __typename?: 'Query' }
  & { driverStatistics?: Maybe<(
    { __typename: 'DriverStatisticsType' }
    & Pick<DriverStatisticsType, 'ordersAmount' | 'bagsAmount' | 'ordersTotalCost' | 'driverEarnings'>
  )> }
);

export type OrdersQueryVariables = Exact<{
  ordersBag?: Maybe<Scalars['ID']>;
  status?: Maybe<Array<Maybe<OrderDeliveryStatus>> | Maybe<OrderDeliveryStatus>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type OrdersQuery = (
  { __typename?: 'Query' }
  & { orders?: Maybe<(
    { __typename?: 'OrderTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'OrderTypeEdge' }
      & { node?: Maybe<(
        { __typename?: 'OrderType' }
        & Pick<OrderType, 'id' | 'orderNumber' | 'orderStatus' | 'shippingCost' | 'totalCost' | 'customerId' | 'customerPhone' | 'customerFullname' | 'placedAt' | 'createdAt' | 'updatedAt' | 'customerNote' | 'deliveryStatus' | 'deliveryDate' | 'deliveryIntervalStart' | 'deliveryIntervalEnd'>
        & { address: (
          { __typename?: 'AddressType' }
          & Pick<AddressType, 'city' | 'street' | 'house' | 'entrance' | 'floor' | 'flat' | 'fullAddress'>
          & { location?: Maybe<(
            { __typename?: 'GeometryObjectType' }
            & Pick<GeometryObjectType, 'coordinates'>
          )> }
        ), paymentMethod: (
          { __typename?: 'PaymentMethodType' }
          & Pick<PaymentMethodType, 'code' | 'title'>
        ), shippingMethod?: Maybe<(
          { __typename?: 'ShippingMethodType' }
          & Pick<ShippingMethodType, 'code' | 'title'>
        )>, orderinbag?: Maybe<(
          { __typename?: 'OrderInBagType' }
          & Pick<OrderInBagType, 'id' | 'priority'>
        )>, lineItems: (
          { __typename?: 'OrderLineItemTypeConnection' }
          & { edges: Array<Maybe<(
            { __typename?: 'OrderLineItemTypeEdge' }
            & { node?: Maybe<(
              { __typename?: 'OrderLineItemType' }
              & Pick<OrderLineItemType, 'name' | 'productId' | 'variationId' | 'quantity' | 'price' | 'totalCost' | 'subtotalCost' | 'sku'>
            )> }
          )>> }
        ) }
      )> }
    )>> }
  )> }
);

export type ChangeOrderDeliveryStatusMutationVariables = Exact<{
  input: ChangeOrderDeliveryStatusType;
}>;


export type ChangeOrderDeliveryStatusMutation = (
  { __typename?: 'Mutation' }
  & { changeOrderDeliveryStatus?: Maybe<(
    { __typename?: 'ChangeOrderDeliveryStatus' }
    & { order?: Maybe<(
      { __typename?: 'OrderType' }
      & Pick<OrderType, 'id' | 'deliveryStatus'>
    )> }
  )> }
);

export type AddChangeRequestToOrderMutationVariables = Exact<{
  changePaymentInfo: Scalars['String'];
  customerPaidAmount: Scalars['Float'];
  orderId: Scalars['ID'];
}>;


export type AddChangeRequestToOrderMutation = (
  { __typename?: 'Mutation' }
  & { addChangeRequestToOrder?: Maybe<(
    { __typename?: 'AddChangeRequestToOrder' }
    & { order?: Maybe<(
      { __typename?: 'OrderType' }
      & Pick<OrderType, 'id' | 'orderStatus' | 'deliveryStatus'>
    )> }
  )> }
);

export type OrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'OrderType' }
    & Pick<OrderType, 'id' | 'orderNumber' | 'orderStatus' | 'shippingCost' | 'totalCost' | 'customerId' | 'customerFullname' | 'customerPhone' | 'customerNote' | 'deliveryStatus' | 'deliveryDate' | 'deliveryIntervalStart' | 'deliveryIntervalEnd'>
    & { address: (
      { __typename?: 'AddressType' }
      & Pick<AddressType, 'city' | 'street' | 'house' | 'entrance' | 'floor' | 'flat' | 'fullAddress'>
      & { location?: Maybe<(
        { __typename?: 'GeometryObjectType' }
        & Pick<GeometryObjectType, 'coordinates'>
      )> }
    ), paymentMethod: (
      { __typename?: 'PaymentMethodType' }
      & Pick<PaymentMethodType, 'title' | 'code'>
    ), shippingMethod?: Maybe<(
      { __typename?: 'ShippingMethodType' }
      & Pick<ShippingMethodType, 'code' | 'title'>
    )>, orderinbag?: Maybe<(
      { __typename?: 'OrderInBagType' }
      & Pick<OrderInBagType, 'id' | 'priority'>
      & { ordersBag: (
        { __typename?: 'OrdersBagType' }
        & { driver?: Maybe<(
          { __typename?: 'DriverType' }
          & Pick<DriverType, 'id'>
        )> }
      ) }
    )>, lineItems: (
      { __typename?: 'OrderLineItemTypeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'OrderLineItemTypeEdge' }
        & { node?: Maybe<(
          { __typename?: 'OrderLineItemType' }
          & Pick<OrderLineItemType, 'id' | 'name' | 'productId' | 'variationId' | 'quantity' | 'price' | 'totalCost' | 'subtotalCost' | 'sku'>
        )> }
      )>> }
    ) }
  )> }
);

export type DriverActiveOrdersQueryVariables = Exact<{
  ordersBag?: Maybe<Scalars['ID']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type DriverActiveOrdersQuery = (
  { __typename?: 'Query' }
  & { orders?: Maybe<(
    { __typename?: 'OrderTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'OrderTypeEdge' }
      & { node?: Maybe<(
        { __typename?: 'OrderType' }
        & Pick<OrderType, 'id' | 'orderNumber' | 'orderStatus' | 'shippingCost' | 'totalCost' | 'customerId' | 'customerPhone' | 'customerFullname' | 'placedAt' | 'createdAt' | 'updatedAt' | 'customerNote' | 'deliveryStatus' | 'deliveryDate' | 'deliveryIntervalStart' | 'deliveryIntervalEnd'>
        & { address: (
          { __typename?: 'AddressType' }
          & Pick<AddressType, 'city' | 'street' | 'house' | 'entrance' | 'floor' | 'flat' | 'fullAddress'>
          & { location?: Maybe<(
            { __typename?: 'GeometryObjectType' }
            & Pick<GeometryObjectType, 'coordinates'>
          )> }
        ), paymentMethod: (
          { __typename?: 'PaymentMethodType' }
          & Pick<PaymentMethodType, 'code' | 'title'>
        ), shippingMethod?: Maybe<(
          { __typename?: 'ShippingMethodType' }
          & Pick<ShippingMethodType, 'code' | 'title'>
        )>, orderinbag?: Maybe<(
          { __typename?: 'OrderInBagType' }
          & Pick<OrderInBagType, 'id' | 'priority'>
        )>, lineItems: (
          { __typename?: 'OrderLineItemTypeConnection' }
          & { edges: Array<Maybe<(
            { __typename?: 'OrderLineItemTypeEdge' }
            & { node?: Maybe<(
              { __typename?: 'OrderLineItemType' }
              & Pick<OrderLineItemType, 'name' | 'productId' | 'variationId' | 'quantity' | 'price' | 'totalCost' | 'subtotalCost' | 'sku'>
            )> }
          )>> }
        ) }
      )> }
    )>> }
  )> }
);

export type DriverHistoryOrdersQueryVariables = Exact<{
  ordersBag?: Maybe<Scalars['ID']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type DriverHistoryOrdersQuery = (
  { __typename?: 'Query' }
  & { orders?: Maybe<(
    { __typename?: 'OrderTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'OrderTypeEdge' }
      & { node?: Maybe<(
        { __typename?: 'OrderType' }
        & Pick<OrderType, 'id' | 'orderNumber' | 'orderStatus' | 'shippingCost' | 'totalCost' | 'customerId' | 'customerPhone' | 'customerFullname' | 'placedAt' | 'createdAt' | 'updatedAt' | 'customerNote' | 'deliveryStatus' | 'deliveryDate' | 'deliveryIntervalStart' | 'deliveryIntervalEnd'>
        & { address: (
          { __typename?: 'AddressType' }
          & Pick<AddressType, 'city' | 'street' | 'house' | 'entrance' | 'floor' | 'flat' | 'fullAddress'>
          & { location?: Maybe<(
            { __typename?: 'GeometryObjectType' }
            & Pick<GeometryObjectType, 'coordinates'>
          )> }
        ), paymentMethod: (
          { __typename?: 'PaymentMethodType' }
          & Pick<PaymentMethodType, 'code' | 'title'>
        ), shippingMethod?: Maybe<(
          { __typename?: 'ShippingMethodType' }
          & Pick<ShippingMethodType, 'code' | 'title'>
        )>, orderinbag?: Maybe<(
          { __typename?: 'OrderInBagType' }
          & Pick<OrderInBagType, 'id' | 'priority'>
        )>, lineItems: (
          { __typename?: 'OrderLineItemTypeConnection' }
          & { edges: Array<Maybe<(
            { __typename?: 'OrderLineItemTypeEdge' }
            & { node?: Maybe<(
              { __typename?: 'OrderLineItemType' }
              & Pick<OrderLineItemType, 'name' | 'productId' | 'variationId' | 'quantity' | 'price' | 'totalCost' | 'subtotalCost' | 'sku'>
            )> }
          )>> }
        ) }
      )> }
    )>> }
  )> }
);

export type DayIntervalsQueryVariables = Exact<{
  date: Scalars['Date'];
}>;


export type DayIntervalsQuery = (
  { __typename?: 'Query' }
  & { dayIntervals?: Maybe<(
    { __typename?: 'IntervalTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'IntervalTypeEdge' }
      & { node?: Maybe<(
        { __typename?: 'IntervalType' }
        & Pick<IntervalType, 'id' | 'start' | 'end' | 'occupancy' | 'capacity' | 'added'>
      )> }
    )>> }
  )> }
);

export type StatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type StatisticsQuery = (
  { __typename?: 'Query' }
  & { month?: Maybe<(
    { __typename?: 'DriverStatisticsType' }
    & Pick<DriverStatisticsType, 'bagsAmount' | 'driverEarnings' | 'ordersAmount' | 'ordersTotalCost' | 'totalDistance'>
  )>, today?: Maybe<(
    { __typename?: 'DriverStatisticsType' }
    & Pick<DriverStatisticsType, 'bagsAmount' | 'driverEarnings' | 'ordersAmount' | 'ordersTotalCost' | 'totalDistance'>
  )> }
);

export type AttachDriverToIntervalMutationVariables = Exact<{
  date: Scalars['Date'];
  intervalId: Scalars['ID'];
}>;


export type AttachDriverToIntervalMutation = (
  { __typename?: 'Mutation' }
  & { attachDriverToInterval?: Maybe<(
    { __typename?: 'AttachDriverToInterval' }
    & { interval?: Maybe<(
      { __typename?: 'IntervalType' }
      & Pick<IntervalType, 'id' | 'added' | 'occupancy' | 'capacity' | 'start' | 'end'>
    )> }
  )> }
);


export const OrdersBagsDocument = gql`
    query OrdersBags($status: [OrdersBagStatus]) {
  ordersBags(status: $status) {
    edges {
      node {
        id
        bag {
          id
          code
          status
        }
        orders {
          totalCost
          lineItems {
            edges {
              node {
                id
              }
            }
          }
        }
        weight
        takenAt
        finishedAt
        createdAt
        driver {
          vehicle {
            vehicleType
            licensePlate
          }
        }
      }
    }
  }
}
    `;

/**
 * __useOrdersBagsQuery__
 *
 * To run a query within a React component, call `useOrdersBagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersBagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersBagsQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useOrdersBagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrdersBagsQuery, OrdersBagsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrdersBagsQuery, OrdersBagsQueryVariables>(OrdersBagsDocument, baseOptions);
      }
export function useOrdersBagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrdersBagsQuery, OrdersBagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrdersBagsQuery, OrdersBagsQueryVariables>(OrdersBagsDocument, baseOptions);
        }
export type OrdersBagsQueryHookResult = ReturnType<typeof useOrdersBagsQuery>;
export type OrdersBagsLazyQueryHookResult = ReturnType<typeof useOrdersBagsLazyQuery>;
export const OrdersBagDocument = gql`
    query OrdersBag($id: ID!) {
  ordersBag(id: $id) {
    id
    bag {
      id
      status
      code
    }
    weight
    takenAt
    finishedAt
    createdAt
    orders {
      id
      orderNumber
      orderStatus
      shippingCost
      totalCost
      customerId
      customerPhone
      customerFullname
      placedAt
      createdAt
      updatedAt
      address {
        city
        street
        house
        entrance
        floor
        flat
        location {
          coordinates
        }
        fullAddress
      }
      paymentMethod {
        code
        title
      }
      customerNote
      deliveryStatus
      deliveryDate
      deliveryIntervalStart
      deliveryIntervalEnd
      shippingMethod {
        code
        title
      }
      lineItems {
        edges {
          node {
            name
            productId
            variationId
            quantity
            price
            totalCost
            subtotalCost
            sku
          }
        }
      }
    }
    driver {
      id
      vehicle {
        vehicleType
      }
    }
  }
}
    `;

/**
 * __useOrdersBagQuery__
 *
 * To run a query within a React component, call `useOrdersBagQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersBagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersBagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrdersBagQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrdersBagQuery, OrdersBagQueryVariables>) {
        return ApolloReactHooks.useQuery<OrdersBagQuery, OrdersBagQueryVariables>(OrdersBagDocument, baseOptions);
      }
export function useOrdersBagLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrdersBagQuery, OrdersBagQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrdersBagQuery, OrdersBagQueryVariables>(OrdersBagDocument, baseOptions);
        }
export type OrdersBagQueryHookResult = ReturnType<typeof useOrdersBagQuery>;
export type OrdersBagLazyQueryHookResult = ReturnType<typeof useOrdersBagLazyQuery>;
export const AddDriverToOrdersBagDocument = gql`
    mutation AddDriverToOrdersBag($input: AddDriverToOrdersBagType!) {
  addDriverToOrdersBag(input: $input) {
    ordersBag {
      id
    }
  }
}
    `;

/**
 * __useAddDriverToOrdersBagMutation__
 *
 * To run a mutation, you first call `useAddDriverToOrdersBagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDriverToOrdersBagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDriverToOrdersBagMutation, { data, loading, error }] = useAddDriverToOrdersBagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDriverToOrdersBagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDriverToOrdersBagMutation, AddDriverToOrdersBagMutationVariables>) {
        return ApolloReactHooks.useMutation<AddDriverToOrdersBagMutation, AddDriverToOrdersBagMutationVariables>(AddDriverToOrdersBagDocument, baseOptions);
      }
export type AddDriverToOrdersBagMutationHookResult = ReturnType<typeof useAddDriverToOrdersBagMutation>;
export type AddDriverToOrdersBagMutationOptions = ApolloReactCommon.BaseMutationOptions<AddDriverToOrdersBagMutation, AddDriverToOrdersBagMutationVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    edges {
      node {
        id
        email
        phone
        createdAt
      }
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export const TokenAuthDocument = gql`
    mutation TokenAuth($email: String!, $password: String!) {
  tokenAuth(input: {email: $email, password: $password}) {
    token
    user {
      id
      email
      phone
      firstName
      secondName
      role
      roleName
    }
  }
}
    `;

/**
 * __useTokenAuthMutation__
 *
 * To run a mutation, you first call `useTokenAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenAuthMutation, { data, loading, error }] = useTokenAuthMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useTokenAuthMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TokenAuthMutation, TokenAuthMutationVariables>) {
        return ApolloReactHooks.useMutation<TokenAuthMutation, TokenAuthMutationVariables>(TokenAuthDocument, baseOptions);
      }
export type TokenAuthMutationHookResult = ReturnType<typeof useTokenAuthMutation>;
export type TokenAuthMutationOptions = ApolloReactCommon.BaseMutationOptions<TokenAuthMutation, TokenAuthMutationVariables>;
export const UserRegisterDocument = gql`
    mutation UserRegister($email: String!, $phone: String!, $role: UserRole) {
  userRegister(input: {email: $email, phone: $phone, role: $role}) {
    user {
      phone
      email
      role
    }
  }
}
    `;

/**
 * __useUserRegisterMutation__
 *
 * To run a mutation, you first call `useUserRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRegisterMutation, { data, loading, error }] = useUserRegisterMutation({
 *   variables: {
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUserRegisterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserRegisterMutation, UserRegisterMutationVariables>) {
        return ApolloReactHooks.useMutation<UserRegisterMutation, UserRegisterMutationVariables>(UserRegisterDocument, baseOptions);
      }
export type UserRegisterMutationHookResult = ReturnType<typeof useUserRegisterMutation>;
export type UserRegisterMutationOptions = ApolloReactCommon.BaseMutationOptions<UserRegisterMutation, UserRegisterMutationVariables>;
export const DriverStatisticsDocument = gql`
    query DriverStatistics($driverId: ID, $period: StatisticsPeriodEnum) {
  driverStatistics(driverId: $driverId, period: $period) {
    __typename
    ordersAmount
    bagsAmount
    ordersTotalCost
    driverEarnings
  }
}
    `;

/**
 * __useDriverStatisticsQuery__
 *
 * To run a query within a React component, call `useDriverStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriverStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriverStatisticsQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useDriverStatisticsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DriverStatisticsQuery, DriverStatisticsQueryVariables>) {
        return ApolloReactHooks.useQuery<DriverStatisticsQuery, DriverStatisticsQueryVariables>(DriverStatisticsDocument, baseOptions);
      }
export function useDriverStatisticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DriverStatisticsQuery, DriverStatisticsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DriverStatisticsQuery, DriverStatisticsQueryVariables>(DriverStatisticsDocument, baseOptions);
        }
export type DriverStatisticsQueryHookResult = ReturnType<typeof useDriverStatisticsQuery>;
export type DriverStatisticsLazyQueryHookResult = ReturnType<typeof useDriverStatisticsLazyQuery>;
export const OrdersDocument = gql`
    query Orders($ordersBag: ID, $status: [OrderDeliveryStatus], $offset: Int, $before: String, $after: String, $first: Int, $last: Int) {
  orders(
    ordersBag: $ordersBag
    status: $status
    offset: $offset
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    edges {
      node {
        id
        orderNumber
        orderStatus
        shippingCost
        totalCost
        customerId
        customerPhone
        customerFullname
        placedAt
        createdAt
        updatedAt
        address {
          city
          street
          house
          entrance
          floor
          flat
          location {
            coordinates
          }
          fullAddress
        }
        paymentMethod {
          code
          title
        }
        customerNote
        deliveryStatus
        deliveryDate
        deliveryIntervalStart
        deliveryIntervalEnd
        shippingMethod {
          code
          title
        }
        orderinbag {
          id
          priority
        }
        lineItems {
          edges {
            node {
              name
              productId
              variationId
              quantity
              price
              totalCost
              subtotalCost
              sku
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      ordersBag: // value for 'ordersBag'
 *      status: // value for 'status'
 *      offset: // value for 'offset'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, baseOptions);
      }
export function useOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, baseOptions);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export const ChangeOrderDeliveryStatusDocument = gql`
    mutation ChangeOrderDeliveryStatus($input: ChangeOrderDeliveryStatusType!) {
  changeOrderDeliveryStatus(input: $input) {
    order {
      id
      deliveryStatus
    }
  }
}
    `;

/**
 * __useChangeOrderDeliveryStatusMutation__
 *
 * To run a mutation, you first call `useChangeOrderDeliveryStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeOrderDeliveryStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeOrderDeliveryStatusMutation, { data, loading, error }] = useChangeOrderDeliveryStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeOrderDeliveryStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeOrderDeliveryStatusMutation, ChangeOrderDeliveryStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeOrderDeliveryStatusMutation, ChangeOrderDeliveryStatusMutationVariables>(ChangeOrderDeliveryStatusDocument, baseOptions);
      }
export type ChangeOrderDeliveryStatusMutationHookResult = ReturnType<typeof useChangeOrderDeliveryStatusMutation>;
export type ChangeOrderDeliveryStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeOrderDeliveryStatusMutation, ChangeOrderDeliveryStatusMutationVariables>;
export const AddChangeRequestToOrderDocument = gql`
    mutation AddChangeRequestToOrder($changePaymentInfo: String!, $customerPaidAmount: Float!, $orderId: ID!) {
  addChangeRequestToOrder(
    changePaymentInfo: $changePaymentInfo
    customerPaidAmount: $customerPaidAmount
    orderId: $orderId
  ) {
    order {
      id
      orderStatus
      deliveryStatus
    }
  }
}
    `;

/**
 * __useAddChangeRequestToOrderMutation__
 *
 * To run a mutation, you first call `useAddChangeRequestToOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChangeRequestToOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChangeRequestToOrderMutation, { data, loading, error }] = useAddChangeRequestToOrderMutation({
 *   variables: {
 *      changePaymentInfo: // value for 'changePaymentInfo'
 *      customerPaidAmount: // value for 'customerPaidAmount'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useAddChangeRequestToOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddChangeRequestToOrderMutation, AddChangeRequestToOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<AddChangeRequestToOrderMutation, AddChangeRequestToOrderMutationVariables>(AddChangeRequestToOrderDocument, baseOptions);
      }
export type AddChangeRequestToOrderMutationHookResult = ReturnType<typeof useAddChangeRequestToOrderMutation>;
export type AddChangeRequestToOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<AddChangeRequestToOrderMutation, AddChangeRequestToOrderMutationVariables>;
export const OrderDocument = gql`
    query Order($id: ID!) {
  order(id: $id) {
    id
    orderNumber
    orderStatus
    shippingCost
    totalCost
    customerId
    customerFullname
    customerPhone
    address {
      city
      street
      house
      entrance
      floor
      flat
      location {
        coordinates
      }
      fullAddress
    }
    paymentMethod {
      title
      code
    }
    customerNote
    deliveryStatus
    deliveryDate
    deliveryIntervalStart
    deliveryIntervalEnd
    shippingMethod {
      code
      title
    }
    orderinbag {
      id
      priority
      ordersBag {
        driver {
          id
        }
      }
    }
    lineItems {
      edges {
        node {
          id
          name
          productId
          variationId
          quantity
          price
          totalCost
          subtotalCost
          sku
        }
      }
    }
  }
}
    `;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        return ApolloReactHooks.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, baseOptions);
      }
export function useOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, baseOptions);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export const DriverActiveOrdersDocument = gql`
    query DriverActiveOrders($ordersBag: ID, $offset: Int, $before: String, $after: String, $first: Int, $last: Int) {
  orders: driverActiveOrders(
    ordersBag: $ordersBag
    offset: $offset
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    edges {
      node {
        id
        orderNumber
        orderStatus
        shippingCost
        totalCost
        customerId
        customerPhone
        customerFullname
        placedAt
        createdAt
        updatedAt
        address {
          city
          street
          house
          entrance
          floor
          flat
          location {
            coordinates
          }
          fullAddress
        }
        paymentMethod {
          code
          title
        }
        customerNote
        deliveryStatus
        deliveryDate
        deliveryIntervalStart
        deliveryIntervalEnd
        shippingMethod {
          code
          title
        }
        orderinbag {
          id
          priority
        }
        lineItems {
          edges {
            node {
              name
              productId
              variationId
              quantity
              price
              totalCost
              subtotalCost
              sku
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDriverActiveOrdersQuery__
 *
 * To run a query within a React component, call `useDriverActiveOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriverActiveOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriverActiveOrdersQuery({
 *   variables: {
 *      ordersBag: // value for 'ordersBag'
 *      offset: // value for 'offset'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useDriverActiveOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DriverActiveOrdersQuery, DriverActiveOrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<DriverActiveOrdersQuery, DriverActiveOrdersQueryVariables>(DriverActiveOrdersDocument, baseOptions);
      }
export function useDriverActiveOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DriverActiveOrdersQuery, DriverActiveOrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DriverActiveOrdersQuery, DriverActiveOrdersQueryVariables>(DriverActiveOrdersDocument, baseOptions);
        }
export type DriverActiveOrdersQueryHookResult = ReturnType<typeof useDriverActiveOrdersQuery>;
export type DriverActiveOrdersLazyQueryHookResult = ReturnType<typeof useDriverActiveOrdersLazyQuery>;
export const DriverHistoryOrdersDocument = gql`
    query DriverHistoryOrders($ordersBag: ID, $offset: Int, $before: String, $after: String, $first: Int, $last: Int) {
  orders: driverHistoryOrders(
    ordersBag: $ordersBag
    offset: $offset
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    edges {
      node {
        id
        orderNumber
        orderStatus
        shippingCost
        totalCost
        customerId
        customerPhone
        customerFullname
        placedAt
        createdAt
        updatedAt
        address {
          city
          street
          house
          entrance
          floor
          flat
          location {
            coordinates
          }
          fullAddress
        }
        paymentMethod {
          code
          title
        }
        customerNote
        deliveryStatus
        deliveryDate
        deliveryIntervalStart
        deliveryIntervalEnd
        shippingMethod {
          code
          title
        }
        orderinbag {
          id
          priority
        }
        lineItems {
          edges {
            node {
              name
              productId
              variationId
              quantity
              price
              totalCost
              subtotalCost
              sku
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDriverHistoryOrdersQuery__
 *
 * To run a query within a React component, call `useDriverHistoryOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriverHistoryOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriverHistoryOrdersQuery({
 *   variables: {
 *      ordersBag: // value for 'ordersBag'
 *      offset: // value for 'offset'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useDriverHistoryOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DriverHistoryOrdersQuery, DriverHistoryOrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<DriverHistoryOrdersQuery, DriverHistoryOrdersQueryVariables>(DriverHistoryOrdersDocument, baseOptions);
      }
export function useDriverHistoryOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DriverHistoryOrdersQuery, DriverHistoryOrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DriverHistoryOrdersQuery, DriverHistoryOrdersQueryVariables>(DriverHistoryOrdersDocument, baseOptions);
        }
export type DriverHistoryOrdersQueryHookResult = ReturnType<typeof useDriverHistoryOrdersQuery>;
export type DriverHistoryOrdersLazyQueryHookResult = ReturnType<typeof useDriverHistoryOrdersLazyQuery>;
export const DayIntervalsDocument = gql`
    query DayIntervals($date: Date!) {
  dayIntervals(date: $date) {
    edges {
      node {
        id
        start
        end
        occupancy
        capacity
        added
      }
    }
  }
}
    `;

/**
 * __useDayIntervalsQuery__
 *
 * To run a query within a React component, call `useDayIntervalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDayIntervalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDayIntervalsQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDayIntervalsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DayIntervalsQuery, DayIntervalsQueryVariables>) {
        return ApolloReactHooks.useQuery<DayIntervalsQuery, DayIntervalsQueryVariables>(DayIntervalsDocument, baseOptions);
      }
export function useDayIntervalsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DayIntervalsQuery, DayIntervalsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DayIntervalsQuery, DayIntervalsQueryVariables>(DayIntervalsDocument, baseOptions);
        }
export type DayIntervalsQueryHookResult = ReturnType<typeof useDayIntervalsQuery>;
export type DayIntervalsLazyQueryHookResult = ReturnType<typeof useDayIntervalsLazyQuery>;
export const StatisticsDocument = gql`
    query Statistics {
  month: driverStatistics(period: month) {
    bagsAmount
    driverEarnings
    ordersAmount
    ordersTotalCost
    totalDistance
  }
  today: driverStatistics(period: today) {
    bagsAmount
    driverEarnings
    ordersAmount
    ordersTotalCost
    totalDistance
  }
}
    `;

/**
 * __useStatisticsQuery__
 *
 * To run a query within a React component, call `useStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatisticsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StatisticsQuery, StatisticsQueryVariables>) {
        return ApolloReactHooks.useQuery<StatisticsQuery, StatisticsQueryVariables>(StatisticsDocument, baseOptions);
      }
export function useStatisticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StatisticsQuery, StatisticsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StatisticsQuery, StatisticsQueryVariables>(StatisticsDocument, baseOptions);
        }
export type StatisticsQueryHookResult = ReturnType<typeof useStatisticsQuery>;
export type StatisticsLazyQueryHookResult = ReturnType<typeof useStatisticsLazyQuery>;
export const AttachDriverToIntervalDocument = gql`
    mutation AttachDriverToInterval($date: Date!, $intervalId: ID!) {
  attachDriverToInterval(date: $date, intervalId: $intervalId) {
    interval {
      id
      added
      occupancy
      capacity
      start
      end
    }
  }
}
    `;

/**
 * __useAttachDriverToIntervalMutation__
 *
 * To run a mutation, you first call `useAttachDriverToIntervalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachDriverToIntervalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachDriverToIntervalMutation, { data, loading, error }] = useAttachDriverToIntervalMutation({
 *   variables: {
 *      date: // value for 'date'
 *      intervalId: // value for 'intervalId'
 *   },
 * });
 */
export function useAttachDriverToIntervalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AttachDriverToIntervalMutation, AttachDriverToIntervalMutationVariables>) {
        return ApolloReactHooks.useMutation<AttachDriverToIntervalMutation, AttachDriverToIntervalMutationVariables>(AttachDriverToIntervalDocument, baseOptions);
      }
export type AttachDriverToIntervalMutationHookResult = ReturnType<typeof useAttachDriverToIntervalMutation>;
export type AttachDriverToIntervalMutationOptions = ApolloReactCommon.BaseMutationOptions<AttachDriverToIntervalMutation, AttachDriverToIntervalMutationVariables>;