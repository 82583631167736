import { IonContent, IonPage } from '@ionic/react';
import React from 'react';

import { Dashboard } from '../../components/dashboard/Dashboard';
import { Title } from '../../components/title/Title';

const Home: React.FC = () => {
  return (
    <IonPage>
      <Title translucent title="Home" menuButton />
      <IonContent>
        <Dashboard />
      </IonContent>
    </IonPage>
  );
};

export { Home };
