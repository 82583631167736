import React from 'react';
import { IonButton, IonCol, IonRow } from '@ionic/react';
import classNames from 'classnames/bind';
import styles from '../OrderDetail.module.scss';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

interface IOrderDetailMoneyChange {
  toggleChangeModal: () => void;
  moneyChangeLoading: boolean;
}

const OrderDetailMoneyChange: React.FC<IOrderDetailMoneyChange> = ({ moneyChangeLoading, toggleChangeModal }) => {
  const { t } = useTranslation();

  return (
    <IonRow className={cx('ion-no-padding')}>
      <IonCol>
        <IonButton
          className={cx('order__button', 'moneyChange__button')}
          onClick={toggleChangeModal}
          color="primary"
          expand="block"
          disabled={moneyChangeLoading}
        >
          {t('Need change')}
        </IonButton>
      </IonCol>
    </IonRow>
  );
};

export default OrderDetailMoneyChange;
