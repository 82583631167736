import React from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonRow } from '@ionic/react';
import { addCurrecny } from '../../../utils/orders';
import classNames from 'classnames/bind';
import styles from '../OrderDetail.module.scss';
import { useTranslation } from 'react-i18next';

interface IOrderDetailTotal {
  productsTotalCost: number;
  shippingCost: number;
}

const cx = classNames.bind(styles);

const OrderDetailTotal: React.FC<IOrderDetailTotal> = ({ productsTotalCost, shippingCost }) => {
  const { t } = useTranslation();

  return (
    <IonCard>
      <IonCardHeader color="primary" className={cx('order-details-header')}>
        <IonCardTitle className={cx('order-details-title')}>{t('Total')}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className={cx('ion-padding ion-margin-top', 'order-details-description')}>
        <IonRow>
          <IonCol size="6" className={cx('total-fontSize')}>
            {t('Products Price')}
          </IonCol>
          <IonCol className={cx('total-fontSize', 'ion-text-right')} size="6">
            {addCurrecny(productsTotalCost)}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="6" className={cx('total-fontSize')}>
            {t('Shipping Cost')}
          </IonCol>
          <IonCol className={cx('total-fontSize', 'ion-text-right')} size="6">
            {addCurrecny(shippingCost)}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="6" className={cx('total-fontSize')}>
            {t('Discount')}
          </IonCol>
          <IonCol className={cx('total-fontSize', 'ion-text-right')} size="6">
            {addCurrecny(0)}
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size="6">
            <span className={cx('detail__label', 'total-fontSize')}>{t('Total')}</span>
          </IonCol>
          <IonCol className="ion-text-right" size="6">
            <span className={cx('detail__label', 'total-fontSize')}>{addCurrecny(productsTotalCost)}</span>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  );
};

export default OrderDetailTotal;
