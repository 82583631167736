import React from 'react';
import { IonCard, IonCardContent, IonCol, IonRow } from '@ionic/react';
import { getBagStatus } from '../../utils/orders';
import { addCurrency, getBagTotalAmount } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import styles from '../../pages/bags/Bags.module.scss';
import { useHistory } from 'react-router-dom';
import { OrdersBagType } from '../../generated/graphql';

interface IBagCard {
  bag: OrdersBagType;
}

const BagCard: React.FC<IBagCard> = ({ bag }) => {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const { push, location } = useHistory();

  return (
    <IonCard
      className={cx('card')}
      key={bag!.bag!.code}
      onClick={() => push(`${location.pathname}/bag/${bag.bag!.code}`, { bagCode: bag.bag!.code, bagId: bag.id })}
    >
      <IonCardContent className={cx('card__content')}>
        <IonRow className={'ion-no-padding'}>
          <IonCol size={'2'} className={'ion-no-padding'}></IonCol>
          <IonCol size={'8'} className={cx('order__descr', 'ion-no-padding', 'ion-text-center')}>
            <span className={cx('font--bold')}>{t('Bag') + ' №' + bag.bag!.code}</span>
          </IonCol>
          <IonCol size={'2'} className={'ion-no-padding'}>
            <div className={cx('status__container')}>
              <div className={cx('status', `status--${getBagStatus(bag.bag!.status)}`)}></div>
            </div>
          </IonCol>
        </IonRow>
        <IonRow className={'ion-no-padding'}>
          <IonCol size={'12'} className={cx('order__descr', 'ion-no-padding')}>
            <span className={cx('font--bold')}>{t('Orders') + ': '}</span>
            {' ' + bag.orders?.length}
          </IonCol>
        </IonRow>
        <IonRow className={'ion-no-padding'}>
          <IonCol size={'12'} className={cx('order__descr', 'ion-no-padding')}>
            <span className={cx('font--bold')}>{t('Total amount') + ': '}</span>
            {addCurrency(getBagTotalAmount(bag.orders ?? []), 'rub')}
          </IonCol>
        </IonRow>
        <IonRow className={'ion-no-padding'}>
          <IonCol size={'12'} className={cx('order__descr', 'ion-no-padding')}>
            <span className={cx('font--bold')}>{t('Total weight') + ': '}</span>
            {bag.weight} кг
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  );
};

export default BagCard;
