import React from 'react';
import { IonButton, IonCol, IonContent, IonGrid, IonInput, IonItem, IonPage, IonRow, IonText } from '@ionic/react';

// styles
import styles from './SigninPage.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

// components
import { ScreensSwitcher } from '../../screensSwitcher/ScreensSwitcher';
import { SigninCodeInput } from '../../components/signin/SigninCodeInput';
import { UserDataForm } from '../../components/user-data-form/UserDataForm';
import { SigninNumberInput } from '../../components/signin/SigninNumberInput';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/auth-context';
import { TokenAuthMutationVariables, useTokenAuthMutation } from '../../generated/graphql';
import { Controller, useForm } from 'react-hook-form';
// import SigninGeolocation from '../../components/signin/SigninGeolocation';

const SigninPage: React.FC = () => {
  const authContext = useAuth();
  const { t } = useTranslation();

  const { handleSubmit, control, errors } = useForm<TokenAuthMutationVariables>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const [tokenAuth] = useTokenAuthMutation({
    onCompleted: (tokenData) => {
      authContext.login(tokenData);
    },
    fetchPolicy: 'no-cache',
  });

  async function handleAuthorization({ email, password }: { email: string; password: string }) {
    try {
      await tokenAuth({
        variables: {
          email,
          password,
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }

  return (
    <IonPage>
      <IonContent>
        {/* <ScreensSwitcher>
          <SigninNumberInput />
          <SigninCodeInput />
          <UserDataForm isRegistration />
        </ScreensSwitcher> */}
        <IonGrid className={cx(styles.ionGrid, 'ion-no-padding')}>
          <div className={styles.inputFieldsContainer}>
            <IonRow className={cx('ion-justify-content-center')}>
              <h1>{t('Login')}</h1>
            </IonRow>
            <IonRow className={cx(styles.inputRow, 'ion-padding')}>
              <IonItem className={classNames(styles.inputItem, { [styles.dangerField]: !!errors.email })} lines="none">
                <Controller
                  render={({ onChange, onBlur }) => (
                    <IonInput
                      onIonChange={(e) => onChange(e.detail.value)}
                      type="email"
                      onIonBlur={onBlur}
                      inputMode="email"
                      placeholder={t('Enter your email')}
                    />
                  )}
                  control={control}
                  name="email"
                  rules={{
                    required: true,
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  }}
                />
              </IonItem>
            </IonRow>

            <IonRow className={cx('ion-padding-horizontal')}>
              <IonItem className={cx('inputItem', { [styles.dangerField]: !!errors.password })} lines="none">
                <Controller
                  render={({ onChange, onBlur }) => (
                    <IonInput
                      onIonChange={(e) => onChange(e.detail.value)}
                      type="password"
                      onIonBlur={onBlur}
                      inputMode="text"
                      placeholder={t('Enter your password')}
                    />
                  )}
                  control={control}
                  name="password"
                  rules={{
                    required: true,
                  }}
                />
              </IonItem>
            </IonRow>
            <IonRow className="ion-padding">
              <IonCol>
                <IonButton
                  onClick={handleSubmit(handleAuthorization)}
                  className={cx(styles.button, 'ion-no-margin')}
                  expand="block"
                >
                  {t('Signin')}
                </IonButton>
              </IonCol>
            </IonRow>
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export { SigninPage };
