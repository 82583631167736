import React from 'react';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonRow,
  isPlatform,
} from '@ionic/react';
import { YandexMap } from '../../map/YandexMap';
import classNames from 'classnames/bind';
import styles from '../OrderDetail.module.scss';
import { useTranslation } from 'react-i18next';
import { GeometryObjectType } from '../../../generated/graphql';

interface IOrderDetailLocation {
  orderLocation: GeometryObjectType;
}

const cx = classNames.bind(styles);

const OrderDetailLocation: React.FC<IOrderDetailLocation> = ({ orderLocation }) => {
  const { t } = useTranslation();

  return (
    <>
      <IonCard className={cx('order-details')}>
        <IonCardHeader className={cx('order-details-header')} color="primary">
          <IonCardTitle className={cx('order-details-title')}>{t('Map')}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent className={cx('order-details-content', 'order-detail-map', 'ion-padding ion-margin-top')}>
          <YandexMap
            mapState={{
              center: [orderLocation.coordinates[1], orderLocation.coordinates[0]],
              zoom: 13,
              controls: [],
            }}
            onAddressConfirm={(coordinates: [number, number]) => console.log(coordinates)}
          />
        </IonCardContent>
      </IonCard>
      <IonRow className="ion-no-padding ion-padding-vertical">
        <IonCol>
          <a
            href={
              isPlatform('android')
                ? `geo:0,0?q=${orderLocation.coordinates[1]},${orderLocation.coordinates[0]}`
                : isPlatform('ios')
                ? `yandexmaps://maps.yandex.ru/?rtext=~${orderLocation.coordinates[1]},${orderLocation.coordinates[0]}`
                : `https://yandex.ru/maps/?rtext=~${orderLocation.coordinates[1]},${orderLocation.coordinates[0]}`
            }
          >
            <IonButton className={cx('open-map')} color="primary" expand="block">
              {t('Open location in another app')}
            </IonButton>
          </a>
        </IonCol>
      </IonRow>
    </>
  );
};

export default OrderDetailLocation;
