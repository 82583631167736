import { format, parseISO } from 'date-fns';
import {OrderType, OrderTypeEdge} from '../generated/graphql';

const convertToMinutes = (sec: number): number => {
  return Math.floor(sec / 60);
};

const convertToSeconds = (sec: number): number => {
  return sec % 60;
};

const UTCLocaleDateTimeConverter = (dateTime: string, toUTC?: boolean) => {
  return new Date(
    Date.parse(new Date(dateTime).toUTCString()) + new Date().getTimezoneOffset() * 60000 * (toUTC ? 1 : -1)
  );
};

const noDelaySetInterval = (cb: () => void, interval: number) => {
  cb();
  return setInterval(cb, interval);
};

const formatDisplayDate = (value: string) => {
  return format(parseISO(value), 'dd/MM/yyyy');
};

const formatDisplayTime = (value: string) => {
  return format(parseISO(value), 'HH:mm');
};

const formatDisplayMonth = (value: string) => {
  return format(parseISO(value), 'LLLL');
};

const addCurrency = (value: string | number, currencyType?: 'rub' | 'usd') => {
  switch (currencyType) {
    case 'rub': {
      return `${value}₽`;
    }
    case 'usd': {
      return `${value}$`;
    }
    default: {
      return `${value}₽`;
    }
  }
};

const getBagTotalAmount = (orders: OrderType[]) => {
  let total = 0;
  orders.map((order: OrderType) => {
    total += order!.totalCost;
  });
  return total;
};

export {
  convertToMinutes,
  convertToSeconds,
  UTCLocaleDateTimeConverter,
  noDelaySetInterval,
  formatDisplayDate,
  formatDisplayTime,
  formatDisplayMonth,
  addCurrency,
  getBagTotalAmount,
};
