import React from 'react';
import { IonGrid, IonRow, IonCol, IonCard, IonIcon, IonCardHeader } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import { useHistory } from 'react-router-dom';
import svgChat from '../../assets/images/icons/dashboard/chatIcon.svg';
import svgDocumentsUpload from '../../assets/images/icons/dashboard/documentsupload.svg';
import svgPrescription from '../../assets/images/icons/dashboard/pre.svg';
import svgMedicine from '../../assets/images/icons/dashboard/med.svg';
import svgSOSContact from '../../assets/images/icons/dashboard/soscontact.svg';
import svgCalendar from '../../assets/images/icons/dashboard/calendar.svg';
import svgSafety from '../../assets/images/icons/dashboard/safetyrules.svg';
import svgBracelet from '../../assets/images/icons/dashboard/bracelet.svg';
import svgYouTube from '../../assets/images/youtube.svg';
import svgFirstAid from '../../assets/images/firstAid.svg';
import svgWoman from '../../assets/images/womanCalendar.svg';
import svgPDFSeizure from '../../assets/images/downloadSeizurePDF.svg';
import svgLogo from '../../assets/images/logoEpi.svg';
import svgSeizure from '../../assets/images/icons/dashboard/seizure.svg';
import styles from './Dashboard.module.scss';
import { AppPage } from '../../routes/Router';

const cx = classNames.bind(styles);

const appPages: AppPage[] = [
  {
    id: 1,
    icon: svgPrescription,
    iconStyle: styles.purple,
    title: 'Multiplicity and dosage of taking PEP',
    url: '/prescriptions',
    description: 'some text',
    cssClasses: cx(styles.ionCard, styles.lightPurpleBckg),
  },
  {
    id: 2,
    icon: svgSeizure,
    iconStyle: styles.green,
    title: 'Record a seizure',
    url: '/seizures',
    description: 'some text',
    cssClasses: cx(styles.ionCard, styles.lightGreenBckg),
  },
  {
    id: 3,
    icon: svgSOSContact,
    iconStyle: styles.green,
    title: 'SOS Contacts',
    url: '/sos-contacts',
    description: 'some text',
    cssClasses: cx(styles.ionCard, styles.lightYellowBckg),
  },
  {
    id: 4,
    icon: svgMedicine,
    iconStyle: styles.cyan,
    title: 'PEP stock control',
    url: '/medicines',
    description: 'some text',
    cssClasses: cx(styles.ionCard, styles.lightCyanBckg),
  },
  {
    id: 5,
    icon: svgCalendar,
    iconStyle: styles.pink,
    title: 'Seizure statistic',
    url: '/calendar',
    description: 'some text',
    cssClasses: cx(styles.ionCard, styles.lightPinkBckg),
  },
  {
    id: 6,
    icon: svgYouTube,
    iconStyle: styles.lgGreen,
    title: 'Video instruction on YouTube',
    url: 'https://www.youtube.com/user/epihelperl',
    description: 'some text',
    cssClasses: cx(styles.ionCard, styles.lightGreen2Bckg),
  },
  {
    id: 7,
    icon: svgSafety,
    iconStyle: styles.antiquebrass,
    title: 'Safety rules',
    url: 'https://xn--e1aahnrdi9a7d.xn--p1ai/',
    description: 'some text',
    cssClasses: cx(styles.ionCard, styles.harmoniesBckg),
  },
  {
    id: 8,
    icon: svgFirstAid,
    iconStyle: '',
    title: 'First aid for a seizure',
    url: 'https://xn--e1aahnrdi9a7d.xn--p1ai/',
    description: 'some text',
    cssClasses: cx(styles.ionCard, styles.lgBlueBckg),
  },
  {
    id: 9,
    icon: svgWoman,
    iconStyle: styles.antiquebrass,
    title: "Women's calendar",
    url: '/women-calendar',
    description: 'some text',
    cssClasses: cx(styles.ionCard, styles.lgPinkBckg),
  },
  {
    id: 10,
    icon: svgPDFSeizure,
    iconStyle: styles.antiquebrass,
    title: 'Download seizure calendar',
    url: '/calendar',
    description: 'some text',
    cssClasses: cx(styles.ionCard, styles.dkGreenBckg),
  },
  {
    id: 11,
    icon: svgBracelet,
    iconStyle: styles.turmeric,
    title: 'Buy a bracelet',
    url: 'https://epihelper.com/',
    description: 'some text',
    cssClasses: cx(styles.ionCard, styles.cornSilkBckg),
  },
  {
    id: 12,
    icon: svgLogo,
    iconStyle: styles.antiquebrass,
    title: 'Helping project',
    url: '',
    description: 'some text',
    cssClasses: cx(styles.ionCard, styles.dkPurpleBckg),
  },
  {
    id: 13,
    icon: svgChat,
    iconStyle: styles.antiquebrass,
    title: 'Chat with doctor',
    url: '/chat',
    description: 'some text',
    cssClasses: cx(styles.ionCard, styles.lgVioletBckg),
  },
  {
    id: 14,
    icon: svgDocumentsUpload,
    iconStyle: styles.antiquebrass,
    title: 'Documents upload',
    url: '',
    description: 'some text',
    cssClasses: cx(styles.ionCard, styles.lgCyanBckg),
  },
];

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <IonGrid>
      <IonRow>
        {appPages.map((appPage) => (
          <IonCol key={appPage.id} size="6" sizeMd="4">
            <IonCard
              className={appPage.cssClasses}
              onClick={() => {
                const openOrPushPage = appPage.url.includes('http') ? window.open : push;
                openOrPushPage(appPage.url);
              }}
            >
              <IonCardHeader className={styles.cardHeader}>
                <div className={styles.iconBckg}>
                  <IonIcon className={appPage.iconStyle} size="large" src={appPage.icon} />
                </div>
              </IonCardHeader>
              <div className={styles.cardContentBlock}>
                <p className={styles.cardTitle}>{t(appPage.title)}</p>
              </div>
            </IonCard>
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};

export { Dashboard };
