import { IonDatetime, IonDatetimeButton, IonModal } from '@ionic/react';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

export interface IDatePicker {
  date: string;
  setDate: Dispatch<SetStateAction<string>>;
}

export const DatePicker: React.FC<IDatePicker> = ({ date, setDate }) => {
  const { t } = useTranslation();
  return (
    <>
      <IonDatetimeButton datetime="datetime" />
      <IonModal keepContentsMounted>
        <IonDatetime
          id="datetime"
          presentation="date"
          value={date}
          showDefaultButtons
          doneText={t('Select')}
          cancelText={t('Cancel')}
          onIonChange={(e) => setDate(e.target!.value! as string)}
        />
      </IonModal>
    </>
  );
};
