import React, { useEffect } from 'react';
import { IonContent, IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react';
import { Title } from '../../components/title/Title';
import { useTranslation } from 'react-i18next';
import {
  OrdersBagStatus,
  OrdersBagTypeEdge,
  useOrdersBagsLazyQuery,
  useStatisticsQuery,
} from '../../generated/graphql';
import { readerOutline } from 'ionicons/icons';
import { EmptyPageGrid } from '../../components/EmptyPageGrid/EmptyPageGrid';
import { DeliveryHistoryStats } from '../../components/delivery-history/DeliveryHistory';
import { BagsList } from '../../components/bagsList/BagsList';
import { useDriverStats } from '../../contexts/statistics-context';

interface IOrdersHistory {}

const DeliveryHistoryBags: React.FC<IOrdersHistory> = () => {
  const { t } = useTranslation();
  const { statisticsLoading, statistics } = useDriverStats();

  const [getOrdersBags, { data: ordersBags, loading: bagsLoading, refetch: refetchBags }] = useOrdersBagsLazyQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      status: [OrdersBagStatus.Finished],
    },
  });

  useEffect(() => {
    getOrdersBags();
  }, []);

  useIonViewWillEnter(() => {
    refetchBags();
  });

  return (
    <IonPage>
      {bagsLoading || statisticsLoading ? (
        <IonLoading isOpen message={t('Loading...')} />
      ) : (
        <>
          <Title
            title="Deliveries history"
            translucent
            menuButton
            refreshButton={{
              refreshButton: true,
              callback: () => {
                refetchBags();
              },
            }}
          />
          <IonContent>
            {ordersBags?.ordersBags?.edges && ordersBags?.ordersBags?.edges.length ? (
              <>
                {statistics && <DeliveryHistoryStats statistics={statistics} />}
                <BagsList ordersBags={ordersBags?.ordersBags?.edges as OrdersBagTypeEdge[]} />
              </>
            ) : (
              <EmptyPageGrid title={'Deliveries history is empty'} icon={readerOutline} />
            )}
          </IonContent>
        </>
      )}
    </IonPage>
  );
};

export { DeliveryHistoryBags };
