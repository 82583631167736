import React, { useEffect, useState } from 'react';
import { IonContent, IonLoading, IonPage, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import classNames from 'classnames/bind';
import { cubeOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { NetworkStatus } from '@apollo/react-hooks';
import { Title } from '../../components/title/Title';
import { OrderType, OrderTypeEdge, useDriverActiveOrdersLazyQuery } from '../../generated/graphql';
import styles from './BagDetail.module.scss';
import { EmptyPageGrid } from '../../components/EmptyPageGrid/EmptyPageGrid';
import { BagDetailComponent } from '../../components/bagDetail/BagDetail';
import BagDetailNavigationButton from '../../components/bagDetail/BagDetailNavigationButton/BagDetailNavigationButton';

const autoRefetchInterval = 3000;

interface ILocation {
  bagId: string;
  bagCode: string;
}

const BagDetail = () => {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const location = useLocation<ILocation>();

  const [getOrders, { data, refetch: refetchOrders, networkStatus }] = useDriverActiveOrdersLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const [bagCode, setBagCode] = useState('');

  useEffect(() => {
    getOrders({ variables: { ordersBag: location.state.bagId } });
  }, []);

  useIonViewWillEnter(() => {
    setBagCode(location.state.bagCode);
    refetchOrders();
  }, [location]);

  useIonViewWillEnter(() => {
    getOrders({
      variables: { ordersBag: location.state.bagId },
      pollInterval: autoRefetchInterval,
    });
  }, []);

  useIonViewWillLeave(() => {
    getOrders({
      variables: { ordersBag: location.state.bagId },
      pollInterval: 0,
    });
  }, []);

  return (
    <IonPage>
      {networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.refetch ? (
        <IonLoading isOpen message={t('Loading...')} />
      ) : (
        <>
          <Title
            translucent
            backButton
            title={`${t('Bag')} №${bagCode}`}
            menuButton
            refreshButton={{
              refreshButton: true,
              callback: () => {
                refetchOrders();
              },
            }}
          >
            <BagDetailNavigationButton orders={data?.orders?.edges.map(({ node }) => node as OrderType)} />
          </Title>
          <IonContent>
            <p className={cx('font--bold', 'ion-text-center')}>{t('Orders')}</p>
            {data?.orders?.edges?.length ? (
              <BagDetailComponent orders={data?.orders?.edges as Array<OrderTypeEdge>} />
            ) : (
              <EmptyPageGrid title={t('Order list is empty')} icon={cubeOutline} />
            )}
          </IonContent>
        </>
      )}
    </IonPage>
  );
};

export { BagDetail };
