import React from 'react';
import classNames from 'classnames/bind';
import styles from './OrderDetailDescrLabel.module.scss';

interface IOrderDetailDescrLabel {
  title: string | number;
  titleClassName?: string;
  descr?: string | number;
}
const OrderDetailDescrLabel: React.FC<IOrderDetailDescrLabel> = ({ title, descr, titleClassName }) => {
  const cx = classNames.bind(styles);
  return (
    <p>
      <span className={cx('title', titleClassName)}>{title}</span>
      {descr}
    </p>
  );
};

export { OrderDetailDescrLabel };
