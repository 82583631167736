import React, { useEffect } from 'react';
import { cubeOutline } from 'ionicons/icons';
import { IonContent, IonLoading, IonPage, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { NetworkStatus } from '@apollo/react-hooks';
import {
  OrdersBagStatus,
  OrdersBagTypeEdge,
  useAddDriverToOrdersBagMutation,
  useOrdersBagsLazyQuery,
} from '../../generated/graphql';
import { Title } from '../../components/title/Title';

import { EnterBagCodeFab } from '../../components/enterBagCodeFab/EnterBagCodeFab';
import { BagsList } from '../../components/bagsList/BagsList';
import { EmptyPageGrid } from '../../components/EmptyPageGrid/EmptyPageGrid';

const autoRefetchInterval = 10000;

const Bags = () => {
  const { t } = useTranslation();

  const [getOrdersBags, { data: ordersBags, networkStatus, refetch: refetchBags }] = useOrdersBagsLazyQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      status: [OrdersBagStatus.Pending, OrdersBagStatus.InProgress],
    },
  });

  const [addDriver] = useAddDriverToOrdersBagMutation();

  const handleAddBag = async (bagCode: string) => {
    await addDriver({
      variables: {
        input: {
          bagCode,
        },
      },
    });

    refetchBags();
  };

  useEffect(() => {
    getOrdersBags();
  }, []);

  useIonViewWillEnter(() => {
    refetchBags();
  });

  useIonViewWillEnter(() => {
    getOrdersBags({
      pollInterval: autoRefetchInterval,
    });
  }, []);

  useIonViewWillLeave(() => {
    getOrdersBags({ pollInterval: 0 });
  }, []);

  return (
    <IonPage>
      {networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.refetch ? (
        <IonLoading isOpen message={t('Loading...')} />
      ) : (
        <>
          <Title
            translucent
            title="Bags"
            menuButton
            refreshButton={{
              refreshButton: true,
              callback: () => {
                refetchBags();
              },
            }}
          />
          <IonContent>
            {ordersBags?.ordersBags?.edges && ordersBags?.ordersBags?.edges.length ? (
              <BagsList ordersBags={ordersBags?.ordersBags?.edges as OrdersBagTypeEdge[]} />
            ) : (
              <EmptyPageGrid icon={cubeOutline} title={'Bags list is empty'} />
            )}
            <EnterBagCodeFab submitHandler={handleAddBag} />
          </IonContent>
        </>
      )}
    </IonPage>
  );
};

export { Bags };
