import React from 'react';
import { IonList } from '@ionic/react';
import { ScheduleListItem } from '../ScheduleListItem/ScheduleListItem';
import { IntervalType } from '../../../generated/graphql';

interface IScheduleList {
  date: string;
  items: IntervalType[];
}

const ScheduleList: React.FC<IScheduleList> = ({ items, date }) => {
  return (
    <IonList>
      {items?.map((item) => {
        return <ScheduleListItem key={item.id} item={item} date={date} />;
      })}
    </IonList>
  );
};

export { ScheduleList };
