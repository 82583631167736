import React, { useCallback, useState } from 'react';
import { Title } from '../../components/title/Title';

import { IonContent, IonLoading, IonPage, useIonToast, useIonViewWillEnter } from '@ionic/react';
import { OrderDetailNote } from '../../components/orderDetail/OrderDetailNote/OrderDetailNote';
import moment from 'moment';
import {
  GeometryObjectType,
  Maybe,
  useOrderLazyQuery,
  useAddChangeRequestToOrderMutation,
} from '../../generated/graphql';

import { useHistory, useLocation } from 'react-router';
import { OrderDeliveryStatus, useChangeOrderDeliveryStatusMutation } from '../../generated/graphql';
import OrderDetailProducts from '../../components/orderDetail/OrderDetailProducts/OrderDetailProducts';
import OrderDetailTotal from '../../components/orderDetail/OrderDetailTotal/OrderDetailTotal';
import OrderDetailLocation from '../../components/orderDetail/OrderDetailLocation/OrderDetailLocation';
import OrderDetailDescription from '../../components/orderDetail/OrderDetailDescription/OrderDetailDescription';
import OrderDetailStatus from '../../components/orderDetail/OrderDetailStatus/OrderDetailStatus';
import OrderDetailMoneyChange from '../../components/orderDetail/OrderDetailMoneyChange/OrderDetailMoneyChange';
import { MoneyChangeModal } from '../../components/UI/MoneyChangeModal/MoneyChangeModal';
import { useTranslation } from 'react-i18next';

interface ILocation {
  orderId: string;
}

const OrderDetail = (props: any) => {
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const [present] = useIonToast();

  const [orderId, setOrderId] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [deliveryInterval, setDeliveryInterval] = useState('');
  const [productsTotalCost, setProductsTotalCost] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [shippingMethod, setShippingMethod] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [entrance, setEntrance] = useState('');
  const [floor, setFloor] = useState('');
  const [apartmentNumber, setApartmentNumber] = useState('');
  const [houseNumber, setHouseNumber] = useState('');
  const [street, setStreet] = useState('');
  const [orderNumber, setOrderNumber] = useState(0);
  const [city, setCity] = useState('');
  const [fullName, setFullName] = useState('');
  const [deliveryStatus, setDeliveryStatus] = useState<OrderDeliveryStatus>();
  const [phone, setPhone] = useState('');
  const [note, setNote] = useState('');
  const [products, setProducts] = useState<any>();
  const [orderLocation, setOrderLocation] = useState<Maybe<GeometryObjectType>>();
  const location = useLocation<ILocation>();
  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [getOrder] = useOrderLazyQuery();
  const [changeOrderDeliveryStatus, { data, loading, error }] = useChangeOrderDeliveryStatusMutation();
  const [addChangeRequestToOrder, { loading: moneyChangeLoading }] = useAddChangeRequestToOrderMutation();

  useIonViewWillEnter(() => {
    setIsLoading(true);

    getOrder({
      variables: {
        id: location.state.orderId,
      },
    }).then((request) => {
      const order = request.data?.order;
      if (order) {
        setOrderId(order.id);
        setOrderNumber(order.orderNumber);
        setProducts(order.lineItems.edges);
        setProductsTotalCost(order.totalCost);
        setShippingCost(order.shippingCost);
        setOrderLocation(order.address.location);
        setShippingMethod(order.shippingMethod!!.title);
        setPaymentMethod(order.paymentMethod.title);
        setDeliveryDate(moment(order.deliveryDate).format('DD.MM.YYYY'));
        setDeliveryInterval(`
                ${moment(`${order.deliveryDate} ${order.deliveryIntervalStart}`).format('HH:mm')}
                -
                ${moment(`${order.deliveryDate} ${order.deliveryIntervalEnd}`).format('HH:mm')}
            `);

        if (order.customerPhone[0] !== '+') {
          setPhone('+' + order.customerPhone);
        } else {
          setPhone(order.customerPhone);
        }

        setNote(order.customerNote);

        setHouseNumber(order.address.house);
        setStreet(order.address.street);
        setCity(order.address.city);

        setEntrance(order.address.entrance);
        setFloor(order.address.floor);
        setApartmentNumber(order.address.flat);

        setDeliveryStatus(order.deliveryStatus);
        setFullName(order.customerFullname);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  }, [props.match.params.id]);

  const handleOrderCompletedButton = () => {
    changeOrderDeliveryStatus({
      onCompleted: () => {
        setDeliveryStatus(OrderDeliveryStatus.A_3);
        goBack();
      },
      variables: {
        input: {
          orderId: orderId,
          deliveryStatus: OrderDeliveryStatus.A_3,
        },
      },
    });
  };

  const handleStartDeliveryButton = () => {
    changeOrderDeliveryStatus({
      onCompleted: () => {
        setDeliveryStatus(OrderDeliveryStatus.A_2);
      },
      variables: {
        input: {
          orderId: orderId,
          deliveryStatus: OrderDeliveryStatus.A_2,
        },
      },
    });
  };

  const toggleChangeModal = useCallback(() => {
    setIsChangeModalOpen(!isChangeModalOpen);
  }, [setIsChangeModalOpen, isChangeModalOpen]);

  const handleAddChangeRequest = useCallback(
    (info: string, amount: number) => {
      addChangeRequestToOrder({
        variables: {
          orderId: String(props.match.params.id),
          changePaymentInfo: info,
          customerPaidAmount: Number(amount),
        },
      }).then(({ data }) => {
        if (!data?.addChangeRequestToOrder) {
          toggleChangeModal();
          present({
            message: t('Something went wrong'),
            duration: 1500,
            position: 'bottom',
            color: 'danger',
          });
        }

        toggleChangeModal();
        present({
          message: t('Data sent successfully!'),
          duration: 1500,
          position: 'bottom',
          color: 'success',
          onDidDismiss: () => {},
        });
        goBack();
      });
    },
    [addChangeRequestToOrder, toggleChangeModal, goBack, present]
  );

  return (
    <IonPage>
      <Title translucent title="Order detail" backButton menuButton />
      <IonContent>
        <IonLoading isOpen={moneyChangeLoading} message={t('Loading...')} />

        {OrderDeliveryStatus.A_2 === deliveryStatus && (
          <MoneyChangeModal
            isOpen={isChangeModalOpen}
            onDismiss={toggleChangeModal}
            onConfirm={handleAddChangeRequest}
            loading={moneyChangeLoading}
          />
        )}
        <OrderDetailStatus
          deliveryStatus={deliveryStatus!}
          handleOrderCompletedButton={handleOrderCompletedButton}
          handleStartDeliveryButton={handleStartDeliveryButton}
        />
        {OrderDeliveryStatus.A_2 === deliveryStatus && (
          <OrderDetailMoneyChange toggleChangeModal={toggleChangeModal} moneyChangeLoading={moneyChangeLoading} />
        )}
        <OrderDetailDescription
          orderNumber={String(orderNumber)}
          houseNumber={houseNumber}
          entrance={entrance}
          floor={floor}
          apartmentNumber={apartmentNumber}
          city={city}
          street={street}
          deliveryInterval={deliveryInterval}
          deliveryDate={deliveryDate}
          shippingMethod={shippingMethod}
          paymentMethod={paymentMethod}
          phone={phone}
          fullName={fullName}
        />
        {note && note !== '' && <OrderDetailNote note={note} />}
        {!!orderLocation && <OrderDetailLocation orderLocation={orderLocation} />}
        <OrderDetailTotal productsTotalCost={productsTotalCost} shippingCost={shippingCost} />
        <OrderDetailProducts products={products} />
      </IonContent>
    </IonPage>
  );
};

export { OrderDetail };
