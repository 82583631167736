import React, { useEffect, useState } from 'react';
import { IonContent, IonLoading, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { ScheduleDay } from '../../components/schedule/ScheduleDay';
import { Title } from '../../components/title/Title';
import { IntervalType, useDayIntervalsQuery } from '../../generated/graphql';

export interface IScheduleDay {
  items: IntervalType[];
}

const Schedule = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'));

  const { data, loading, refetch } = useDayIntervalsQuery({
    variables: {
      date,
    },
  });

  useEffect(() => {
    refetch({ date });
  }, [date, refetch]);

  return (
    <IonPage>
      <IonContent>
        <Title translucent title="Schedule" menuButton />
        {data && !loading ? (
          <ScheduleDay
            scheduleDay={{
              items: data.dayIntervals?.edges.map(({ node }) => node) as IntervalType[],
            }}
            setDate={setDate}
            date={date}
          />
        ) : (
          <IonLoading isOpen message={t('Loading...')} />
        )}
      </IonContent>
    </IonPage>
  );
};

export { Schedule };
