import React from 'react';
import {
  IonAccordion,
  IonAccordionGroup,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCol,
  IonItem,
  IonLabel,
  IonRow,
} from '@ionic/react';

import { useTranslation } from 'react-i18next';
import { addCurrecny } from '../../../utils/orders';

import classNames from 'classnames/bind';
import styles from '../OrderDetail.module.scss';
import { OrderLineItemTypeEdge } from '../../../generated/graphql';

interface IOrderDetailProducts {
  products: OrderLineItemTypeEdge[] | undefined;
}

const cx = classNames.bind(styles);

const OrderDetailProducts: React.FC<IOrderDetailProducts> = ({ products }) => {
  const { t } = useTranslation();
  return (
    <IonCard className={cx('products-wrapper')}>
      <IonCardContent className={cx('order-details-content')}>
        <IonAccordionGroup className={'orderDetail__accordion'}>
          <IonAccordion value="first">
            <IonItem className={cx('order-details-header', 'order-products__header')} slot="header" color="gray">
              <IonLabel className={cx('order-details-title', 'order-products__title')}>{t('Products')}</IonLabel>
            </IonItem>
            <div className="ion-padding" slot="content">
              {products
                ? products.map((item: OrderLineItemTypeEdge) => {
                    return (
                      <div key={item.node.productId}>
                        <IonRow>
                          <IonCol>
                            <IonCardSubtitle className={cx('product__title')}>
                              <strong>{item.node.name}</strong>
                            </IonCardSubtitle>
                          </IonCol>
                        </IonRow>
                        <IonItem lines="full">
                          <IonLabel>
                            <IonRow>
                              <IonCol className="ion-text-left" size="6">
                                <span className={cx('order__productsSpan')}>{t('Price')}&nbsp;:&nbsp;</span>
                              </IonCol>
                              <IonCol className="ion-text-right" size="6">
                                <span className={cx('order__productsSpan')}>
                                  {`${addCurrecny(Number(item.node.price).toFixed())}`}
                                </span>
                              </IonCol>
                            </IonRow>
                            <IonRow>
                              <IonCol className="ion-text-left" size="6">
                                <span className={cx('order__productsSpan')}>{t('Quantity')}&nbsp;:&nbsp;</span>
                              </IonCol>
                              <IonCol className="ion-text-right" size="6">
                                <span className={cx('order__productsSpan')}>{item.node.quantity}</span>
                              </IonCol>
                            </IonRow>
                            <IonRow>
                              <IonCol className="ion-text-left" size="6">
                                <span className={cx('order__productsSpan', 'detail__label')}>{t('Total') + ' : '}</span>
                              </IonCol>
                              <IonCol className="ion-text-right" size="6">
                                <span className={cx('order__productsSpan', 'detail__label')}>
                                  {`${addCurrecny(item.node.totalCost)}`}
                                </span>
                              </IonCol>
                            </IonRow>
                          </IonLabel>
                        </IonItem>
                      </div>
                    );
                  })
                : null}
            </div>
          </IonAccordion>
        </IonAccordionGroup>
      </IonCardContent>
    </IonCard>
  );
};

export default OrderDetailProducts;
