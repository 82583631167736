import React from 'react';
import { IonCol, IonIcon, IonRow } from '@ionic/react';
import { arrowBackOutline, arrowForwardOutline } from 'ionicons/icons';
import { addDays, format } from 'date-fns';
import { DatePicker, IDatePicker } from '../../UI/DateTimePickers/DatePicker';

const ScheduleDayTitle: React.FC<IDatePicker> = ({ date, setDate }) => {
  const incrementDay = () => {
    const newDate = addDays(new Date(date), 1);
    setDate(format(newDate, 'yyyy-MM-dd'));
  };

  const decrementDay = () => {
    const newDate = addDays(new Date(date), -1);
    setDate(format(newDate, 'yyyy-MM-dd'));
  };

  return (
    <IonRow className="ion-align-items-center ion-margin-bottom">
      <IonCol className="ion-margin-start">
        <IonIcon size="large" icon={arrowBackOutline} onClick={decrementDay} />
      </IonCol>
      <IonCol>
        <IonRow className="ion-nowrap ion-justify-content-center ion-no-padding ion-no-margin ion-align-items-center">
          <DatePicker date={date} setDate={setDate} />
        </IonRow>
      </IonCol>
      <IonCol className="ion-text-right ion-margin-end">
        <IonIcon size="large" icon={arrowForwardOutline} onClick={incrementDay} />
      </IonCol>
    </IonRow>
  );
};

export { ScheduleDayTitle };
