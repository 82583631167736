import moment from 'moment';
import { OrdersBagStatus, OrderDeliveryStatus, OrderTypeEdge } from '../generated/graphql';

type GroupedOrdersTitle = {
  type: string;
  date: string;
  id: string;
};

const __formatDate = (field: string, format: string) => {
  return moment(field).format(format);
};

const __getOrdersKeys = (orders: { [key: string]: any }, format: string) => {
  return Object.keys(orders).sort((x, y) => moment(y, format).unix() - moment(x, format).unix());
};

const __groupOrders = (orders: any, groupBy: string) => {
  return orders.reduce((acc: { [key: string]: any }, el: OrderTypeEdge) => {
    let orderDate;

    switch (groupBy) {
      case 'hour': {
        orderDate = __formatDate(`${el.node?.deliveryDate} ${el.node?.deliveryIntervalStart}`, 'YYYY-MM-DD HH:mm');
        break;
      }
      case 'day': {
        orderDate = __formatDate(`${el.node?.deliveryDate}`, 'YYYY-MM-DD');
        break;
      }
      default: {
        orderDate = __formatDate(`${el.node?.deliveryDate}`, 'YYYY-MM-DD');
      }
    }

    if (acc[orderDate]) {
      return { ...acc, [orderDate]: acc[orderDate].concat([el]) };
    }
    return { ...acc, [orderDate]: [el] };
  }, {});
};

export const generateOrdersItems = (orders: Array<OrderTypeEdge>): { [k: string]: any }[] => {
  const ordersByDay = __groupOrders(orders, 'day');
  let ordersByHour: { [key: string]: any } = {};
  Object.keys(ordersByDay).map((key) => {
    const orders = __groupOrders(ordersByDay[key], 'hour');
    const ordersKeys = __getOrdersKeys(orders, 'YYYY-MM-DD HH:mm');
    const sortedOrders = ordersKeys.reduce((acc: ConcatArray<never>[], date) => {
      const sortedItems = orders[date].sort((x: OrderTypeEdge, y: OrderTypeEdge) => {
        return (x.node?.orderinbag?.priority ?? 0) - (y.node?.orderinbag?.priority ?? 0);
      });

      const deliveryEndInterval = `${orders[date][0].node.deliveryDate} ${orders[date][0].node.deliveryIntervalEnd}`;

      return acc.concat([
        {
          type: 'hour',
          date: `${moment(date).format('HH:mm')} - ${moment(deliveryEndInterval).format('HH:mm')}`,
          id: date,
        },
        ...sortedItems,
      ]);
    }, []);
    ordersByHour = {
      ...ordersByHour,
      [key]: sortedOrders,
    };
  });

  return __getOrdersKeys(ordersByHour, 'YYYY-MM-DD').reduce((acc: ConcatArray<never>[], date) => {
    const sortedItems = ordersByHour[date].sort(
      (x: OrderTypeEdge & GroupedOrdersTitle, y: OrderTypeEdge & GroupedOrdersTitle) => {
        if (y.type === 'hour' || x.type === 'hour') {
          return true;
        } else {
          return (
            new Date(`${y.node?.deliveryDate} ${y.node?.deliveryIntervalStart}`).getDate() -
            new Date(`${x.node?.deliveryDate} ${x.node?.deliveryIntervalStart}`).getDate()
          );
        }
      }
    );
    return acc.concat([{ type: 'day', date, id: date }, ...sortedItems]);
  }, []);
};

export const getOrderStatus = (status: string) => {
  switch (status) {
    case OrderDeliveryStatus.A_1: {
      return 'Pending';
    }
    case OrderDeliveryStatus.A_2: {
      return 'Processing';
    }
    case OrderDeliveryStatus.A_3: {
      return 'Completed';
    }
    default: {
      return 'Canceled';
    }
  }
};

export const addCurrecny = (v: string | number) => {
  return v + ' ' + '₽';
};

export const getBagStatus = (status: string) => {
  switch (status) {
    case OrdersBagStatus.Pending: {
      return 'Empty';
    }
    case OrdersBagStatus.InProgress: {
      return 'Waiting__delivery';
    }
    case OrdersBagStatus.Finished: {
      return 'On__delivery';
    }
    default: {
      return 'Empty';
    }
  }
};
