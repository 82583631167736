// not styles
import React from 'react';
import { IonContent, IonPage } from '@ionic/react';

// styles
import { useTranslation } from 'react-i18next';
import styles from './NoConnection.module.scss';

interface Props {
  bluetooth?: boolean;
  internetConnectionStatus?: boolean;
}

const NoConnection: React.FC<Props> = ({ bluetooth, internetConnectionStatus }) => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonContent>
        {internetConnectionStatus && (
          <div className={styles.container}>
            <div className={styles.noInternetConnection} />
            <span className={styles.message}>{t("You're offline. Check your connection.")}</span>
          </div>
        )}
        {bluetooth && (
          <div className={styles.container}>
            <div className={styles.noBluetoothConnection} />
            <span className={styles.message}>{t('Please enable Bluetooth on your device.')}</span>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

NoConnection.defaultProps = {
  bluetooth: undefined,
  internetConnectionStatus: undefined,
};

export { NoConnection };
