import React, { createContext, useContext } from 'react';
import { Exact, StatisticsQuery, useStatisticsQuery } from '../generated/graphql';
import { ApolloError, ApolloQueryResult } from '@apollo/client';

const DriverStatsContext = createContext<{
  statistics: StatisticsQuery | undefined;
  statisticsLoading: boolean;
  error: ApolloError | undefined;
}>(undefined!);

export const DriverStatsProvider = ({ children }: any) => {
  const { data: statistics, loading: statisticsLoading, error } = useStatisticsQuery({});

  return (
    <DriverStatsContext.Provider value={{ statistics, statisticsLoading, error }}>
      {children}
    </DriverStatsContext.Provider>
  );
};

export const useDriverStats = () => useContext(DriverStatsContext);
