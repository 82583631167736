import React from 'react';
import { IScheduleDay } from '../../pages/schedule/Schedule';
import { ScheduleDayTitle } from './ScheduleDayTitle/ScheduleDayTitle';
import { ScheduleList } from './ScheduleList/ScheduleList';
import { IDatePicker } from '../UI/DateTimePickers/DatePicker';

interface ISchedule extends IDatePicker {
  scheduleDay: IScheduleDay;
}

const ScheduleDay: React.FC<ISchedule> = ({ scheduleDay, date, setDate }) => {
  return (
    <div>
      <ScheduleDayTitle date={date} setDate={setDate} />
      <ScheduleList items={scheduleDay.items} date={date} />
    </div>
  );
};

export { ScheduleDay };
