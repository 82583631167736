import React from 'react';
import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import styles from './EmptyPageGrid.module.scss';

const EmptyPageGrid = ({ title = 'No Items found', icon = 'alert-circle-outline' }) => {
  const { t } = useTranslation();
  const cx = classNames.bind(styles);

  return (
    <IonGrid className={cx('emptyPage')}>
      <IonRow>
        <IonCol className={cx('emptyPage__content')}>
          <div>
            <IonIcon className={cx('emptyPage__icon')} icon={icon || alertCircleOutline} />
          </div>
          <p>{t(title)}</p>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export { EmptyPageGrid };
