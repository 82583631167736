import React, { useCallback } from 'react';
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import classNames from 'classnames/bind';
import './MoneyChangeModal.scss';
import styles from './MoneyChangeModal.module.scss';
import { useTranslation } from 'react-i18next';
import { closeOutline } from 'ionicons/icons';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

const cx = classNames.bind(styles);

interface IMoneyChangeModal {
  isOpen: boolean;
  onDismiss: () => void;
  onConfirm: (info: string, amount: number) => void;
  loading: boolean;
}

interface IMoneyChangeModalForm {
  amount: number;
  requisites: string;
}

const MoneyChangeModal: React.FC<IMoneyChangeModal> = ({ isOpen, onDismiss, onConfirm, loading }) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IMoneyChangeModalForm>({
    mode: 'onBlur',
    defaultValues: {
      amount: 0,
      requisites: '',
    },
  });

  const onSubmit: SubmitHandler<IMoneyChangeModalForm> = useCallback(
    (data) => {
      onConfirm(data.requisites, data.amount);
    },
    [onConfirm]
  );

  return (
    <IonModal isOpen={isOpen} id="moneyChange__modal">
      <div className="wrapper">
        <IonToolbar>
          <IonTitle className={cx('title')}>{t('Send change')}</IonTitle>
          <IonButtons slot="end" onClick={() => onDismiss()}>
            <IonButton color={'primary'}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <form className={cx('wrapper')} onSubmit={handleSubmit(onSubmit)}>
          <IonItem className={cx('item')}>
            <IonLabel position={'stacked'}>{`${t('Customer paid')}:`}</IonLabel>
            <Controller
              rules={{
                required: true,
                validate: (amount) => !!Number(amount),
              }}
              render={({ onChange }) => (
                <IonInput
                  onIonChange={(e) => {
                    onChange(e.target.value);
                  }}
                  id={'amount'}
                  type={'number'}
                  min={0}
                  name={'amount'}
                  placeholder={t('Enter amount')}
                />
              )}
              control={control}
              name="amount"
            />
          </IonItem>
          <IonItem className={cx('item')}>
            <IonLabel position={'stacked'}>{`${t('Requisites for change')}:`}</IonLabel>
            <Controller
              rules={{
                required: true,
              }}
              render={({ onChange }) => (
                <IonInput
                  onIonChange={(e) => {
                    onChange(e.target.value);
                  }}
                  id={'requisites'}
                  name={'requisites'}
                  placeholder={t('Enter requisites')}
                />
              )}
              control={control}
              name="requisites"
            />
          </IonItem>
          {errors.amount && <p className={cx('error')}>{t('Wrong amount')}</p>}
          {errors.requisites && <p className={cx('error')}>{t('Requisites are required')}</p>}
          <IonButton type={'submit'} className={cx('button')} color="primary" expand="block" disabled={loading}>
            {t('Confirm')}
          </IonButton>
        </form>
      </div>
    </IonModal>
  );
};

export { MoneyChangeModal };
