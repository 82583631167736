import React from 'react';
import { IonCard, IonCardContent, IonCol, IonIcon, IonRow } from '@ionic/react';
import classNames from 'classnames/bind';
import styles from './Order.module.scss';
import { useHistory } from 'react-router-dom';
import { OrderType } from '../../generated/graphql';
import moment from 'moment';
import { getOrderStatus } from '../../utils/orders';
import { personOutline, timeOutline } from 'ionicons/icons';

interface IOrder {
  order: OrderType;
}
const Order: React.FC<IOrder> = ({ order }) => {
  const cx = classNames.bind(styles);
  const { push } = useHistory();

  return (
    <IonCard
      className={cx('myOrders__card')}
      key={order.orderNumber}
      onClick={() => push(`/order/${order.orderNumber}`, { orderId: order.id })}
    >
      <IonCardContent className={cx('myOrders__cardContent')}>
        {/*<OrderRow title={t('Order ID')} desc={'#' + order.orderNumber} />*/}
        {/*<OrderRow title={t('Order create date')} desc={moment(order.createdAt).format('DD.MM.YYYY')} />*/}
        {/*<OrderRow title={t('Delivery date')} desc={moment(order.deliveryDate).format('DD.MM.YYYY')} />*/}
        <IonRow className={'ion-no-padding'}>
          <IonCol size={'10'} className={cx('order__descr', 'ion-no-padding')}>
            <IonIcon className={cx('order__icon')} icon={timeOutline} />
            <span className={cx('order__span')}>
              {`
                ${moment(`${order.deliveryDate} ${order.deliveryIntervalStart}`).format('HH:mm')}
                -
                ${moment(`${order.deliveryDate} ${order.deliveryIntervalEnd}`).format('HH:mm')}
              `}
            </span>
          </IonCol>
          <IonCol size={'2'} className={'ion-no-padding'}>
            <div className={cx('orderStatus__container')}>
              <div className={cx('orderStatus', `orderStatus--${getOrderStatus(order.deliveryStatus)}`)}>
                {(order.orderinbag?.priority ?? 0) + 1}
              </div>
            </div>
          </IonCol>
        </IonRow>
        <IonRow className={'ion-no-padding'}>
          <IonCol size="12" className={cx('order__descr', 'ion-no-padding')}>
            <IonIcon className={cx('order__icon')} icon={personOutline} />
            <span className={cx('order__span', 'order__fullName')}>{order.customerFullname}</span>
          </IonCol>
        </IonRow>
        <IonRow className={'ion-no-padding'}>
          <IonCol className="ion-text-left ion-no-padding-top ion-no-padding" size="12">
            {`г.${order.address.city} `}
            {`ул.${order.address.street} `}
            {`д.${order.address.house} `}
            {order.address.entrance && order.address.floor && order.address.flat
              ? `п.${order.address.entrance} эт.${order.address.floor} кв.${order.address.flat}`
              : ''}
          </IonCol>
        </IonRow>
        <IonRow className={'ion-no-padding'}>
          <IonCol className="ion-text-left ion-no-padding-top ion-no-padding d-flex ion-justify-content-end" size="12">
            <span className={cx('order__id')}>№ {order.orderNumber}</span>
          </IonCol>
        </IonRow>
        {/*<OrderRow title={t('Total cost')} desc={addCurrency(order.totalCost)} descSpanClassName={cx('font--bold')} />*/}
        {/*<OrderRow*/}
        {/*  title={t('Status')}*/}
        {/*  desc={t(order.deliveryStatus)}*/}
        {/*  firstCol={'4'}*/}
        {/*  secondCol={'8'}*/}
        {/*  descSpanClassName={cx('font--bold', 'orderStatusBar', {*/}
        {/*    cancel: order.deliveryStatus === 'A_4',*/}
        {/*    pending: order.deliveryStatus === 'A_1',*/}
        {/*    processing: order.deliveryStatus === 'A_2',*/}
        {/*    completed: order.deliveryStatus === 'A_3',*/}
        {/*  })}*/}
        {/*/>*/}
      </IonCardContent>
    </IonCard>
  );
};

export { Order };
