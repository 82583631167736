import React, { useMemo } from 'react';
import { IonButton, IonIcon, isPlatform } from '@ionic/react';
import classNames from 'classnames/bind';
import styles from './BagDetailNavigationButton.module.scss';
import { useTranslation } from 'react-i18next';
import { mapOutline } from 'ionicons/icons';
import { OrderType } from '../../../generated/graphql';

const cx = classNames.bind(styles);

interface IBagDetailNavigationButton {
  orders?: OrderType[];
}

const BagDetailNavigationButton: React.FC<IBagDetailNavigationButton> = ({ orders }) => {
  const { t } = useTranslation();

  const url = useMemo(() => {
    if (!orders || orders.length === 0) return;
    const coords = orders
      ?.filter((order) => order.address?.location?.coordinates[0])
      .map((order) => `${order?.address?.location?.coordinates[1]},${order?.address?.location?.coordinates[0]}`)
      .join('~');
    return isPlatform('mobile')
      ? `yandexmaps://maps.yandex.ru/?rtext=~${coords}`
      : `https://yandex.ru/maps/?rtext=~${coords}`;
  }, [orders]);

  return (
    <IonButton
      className={cx('order__button', 'moneyChange__button')}
      href={url}
      target="_blank"
      color="primary"
      expand="block"
      disabled={!url}
    >
      <IonIcon icon={mapOutline}></IonIcon>
    </IonButton>
  );
};

export default BagDetailNavigationButton;
